export const primary = "rgb(3, 3, 98)";
export const secondary = "rgb(238, 238, 238)";
export const successColor = '#74c147';
export const warningColor = 'yellow';
export const errorColor = 'red';

// themes
export const agentColor = "#660066";
export const workerColor = "#990000";
export const paymentColor = "#003300";
export const orgColor = "#006666";