import React from "react";
import styles from "./styles.module.css";

// mui
import { Avatar } from "@mui/material";

// images
import ceo from "src/assets/team/ceo.jpeg";
// import Team from "src/Components/Team";

// components
import Page from "src/Components/Page";

const About: React.FC = () => {
  return (
    <Page>
      <div className={styles.container}>
        <h1>About Us</h1>
        <div className={styles.aboutText}>
          <p>
            Ramna Air International was incorporated in 1995. Since then we have
            supplied more than a hundred thousand workers to all over Malaysia,
            Singapore, Middle-east, Seychelles, Mauritius. Our priority is the
            safety of our workers and then satisfaction of our employers.
            Honesty and delivering on our promises have kept our doors open for
            such long time.
          </p>
        </div>
        {/* message */}
        <>
          <div className={styles.messageContainer}>
            <h1 style={{ textAlign: "center" }}>Our Message</h1>
            <p>Salam & Greetings</p>
            <p>
              As everyone is aware of the fact that current development in IT
              and technologies have turned this planet into a{" "}
              <strong>Global Village</strong>. In this business environment, the
              implementation of business projects are more prompt and practical
              but it has become more dependent on specific human skills and
              manpower. Hence, those countries which desire to develop rapidly
              but lack a human workforce will look towards the countries that
              are densely populated and can provide a human workforce on basis
              of mutual coordination.
            </p>
            <p>
              At this juncture, <strong>Bangladesh</strong> comes into the lime
              light. Bangladesh has one of the largest population densities
              around the world with a population of almost 167,126,990 million{" "}
              <a
                href="https://www.worldometers.info/world-population/bangladesh-population/"
                target="_blank"
                rel="noreferrer"
              >
                (Worldmeter).
              </a>{" "}
              Due to tough living conditions and competitive working
              environment, our country has become the source for supply of
              skilled professional and unskilled workforce. Hundreds of
              thousands of Bangladeshis are working in various sectors the world
              over due to their loyalty to employers and dedication to duties.
            </p>
            <p>
              With this idea in mind, <strong>Ramna Air International</strong>{" "}
              was established as a licenced recruiting agency in 2009. Since
              then, we have mobilized thousands of workers in various categories
              to different countries in the Middle East and far East. Our
              network of business is highly professional and includes:
            </p>
            <ol>
              <li>
                Qualified staff for sourcing the right man for the right job.
              </li>
              <li>
                Computerized data bank of professional and skilled workforce.
              </li>
              <li>
                Our travel agency namely{" "}
                <strong>Ramna Air International</strong> arrange and facilitate
                the journey of workforce to overseas and technical track test
                center{" "}
              </li>
            </ol>
            <p style={{ fontWeight: "bold", paddingTop: 10 }}>
              Our utmost assurance is to provide you with the best possible
              services in these regards
            </p>
            {/* sign */}
            <div className={styles.signContainer}>
              <Avatar
                sx={{ width: 100, height: 100 }}
                alt="Shahidul Islam"
                src={ceo}
              />
              <p style={{ fontSize: 20, fontWeight: "bold" }}>Shahidul Islam</p>
              <p>Proprietor - Ramna Air International</p>
            </div>
          </div>
        </>
        {/* team */}
        {/* <>
          <h3 className="section-heading">Meet The Team</h3>
          <Team />
        </> */}
      </div>
    </Page>
  );
};

export default About;
