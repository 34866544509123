import React, { useState, useEffect, useContext } from "react";
import styles from "./styles.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { OrganizationType } from "src/interfaces/Organizations";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";

// firebase
import {
  doc,
  getDoc,
  getFirestore,
  deleteDoc,
  setDoc,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";

// components
import AdminPage from "src/Components/AdminPage";
import { orgLink } from "src/constants/urls";
import ConfirmModal from "src/Components/ConfirmModal";
import UpdateOrg from "./UpdateOrg";
import { elevation } from "src/constants/elevation";
import WorkersList from "../../Components/WorkersList";
import { WorkerType } from "src/interfaces/Workers";

// mui
import { Grid, Paper, styled, Button } from "@mui/material";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";

// icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { orgColor } from "../../constants/colors";
import { UserContext } from "src/context/UserContext";

const OrgDetails: React.FC = () => {
  // @ts-ignore
  const { user } = useContext(UserContext);
  const [data, setData] = useState({} as OrganizationType);
  const [workers, setWorkers] = useState<WorkerType[]>([]);
  const [loading, setLoading] = useState(true);
  const [workerLoading, setWorkerLoading] = useState(true);
  const [open, setOpen] = useState(false); //confirmation modal
  const [backdrop, setBackdrop] = useState(false); //for backdrop

  // dialog
  const [dialogOpen, setDialogOpen] = useState(false);

  // update details form
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [noOfWork, setNoOfWork] = useState("0");
  const [note, setNote] = useState("");
  const [address, setAddress] = useState("");

  const [error, setError] = useState(false);
  const [noWorkError, setNoWorkError] = useState(false);
  const [availableWork, setAvailableWork] = useState(0);
  // grab location to grab orgid
  const orgId = useLocation()?.pathname?.split("/")[2];

  // hooks
  const db = getFirestore();
  const navigate = useNavigate();

  // ref to org with id
  const orgRef = doc(db, "organizations", orgId);
  const workerRef = collection(db, "workers");

  // grab data from firebase
  const grabData = async () => {
    try {
      const docSnap = await getDoc(orgRef);

      if (docSnap.exists()) {
        const appendObj = { ...docSnap.data(), id: docSnap.id };
        // @ts-ignore
        setData(appendObj);
      } else {
        // doc.data() will be undefined in this case
        toast.error("No Organization Found!");
      }
      //   stop loading
      setLoading(false);
    } catch (e) {
      toast.error("Something went wrong. Please reload the window");
    }
  };

  // grab all workers tied to this org
  const grabWorkers = async () => {
    // grab data from firebase
    const q = query(workerRef, where("orgId", "==", orgId));
    try {
      const arr: WorkerType[] = [];
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc: any) => {
        const data = doc.data();
        const appendObj = { ...data, id: doc.id };
        // @ts-ignore
        arr.push(appendObj);
      });
      setWorkers(arr);
      setWorkerLoading(false);
    } catch (e) {
      toast.error("Something went wrong. Please reload the window");
    }
  };

  // when data is updated modal, populate text fields
  useEffect(() => {
    setName(data?.name);
    // @ts-ignore
    setEmail(data?.email);
    // @ts-ignore
    setMobile(data?.mobile);
    setNoOfWork(data?.noOfWork);
    setNote(data?.note);
    // @ts-ignore
    setAddress(data?.address);
  }, [data]);

  // get user data
  useEffect(() => {
    grabData();
    grabWorkers();

    // cleanup
    return () => {
      setData({} as OrganizationType);
      setWorkers([]);
      setLoading(true);
      setOpen(false);
      setDialogOpen(false);
      setAddress("");
      setName("");
      setEmail("");
      setMobile("");
      setNoOfWork("");
      setNote("");
      setError(false);
      setNoWorkError(false);
      setBackdrop(false);
      setWorkerLoading(true);
      setAvailableWork(0);
    };
  }, []);

  // modal and dialog helper functions
  const ToggleModal = () => {
    setOpen(!open);
  };

  const toggleDialog = () => {
    setDialogOpen(!dialogOpen);
  };

  // firebase actions
  const deleteData = async () => {
    ToggleModal();
    setBackdrop(true);
    // delete from firebase
    try {
      await deleteDoc(doc(db, "organizations", orgId));
      toast.info(`${data?.name} has been removed`);
      navigate(orgLink);
      setBackdrop(false);
    } catch (e) {
      toast.error("Something went wrong, Please try again");
      setBackdrop(false);
    }
  };

  // update data
  const updateData = () => {
    if (user?.userEmail === "manager@ramnaair.com") {
      toast.error("You can't perform this operation");
    } else if (window.confirm("Do you want to update this Organization?")) {
      setBackdrop(true);
      setDoc(doc(db, "organizations", orgId), {
        name: name,
        email: email,
        mobile: mobile,
        noOfWork: Number(noOfWork),
        note: note,
        address: address,
      })
        .then(() => {
          setBackdrop(false);
          toast.info(`${name} has been updated`);
          toggleDialog(); //close dialog
        })
        .then(() => grabData()) //get data again
        .catch(() => {
          toast.error("Something went wrong, please try again!");
          setBackdrop(false);
        });
    }
  };

  // validations
  const validateEmail = () => {
    if (!email.includes("@") || !email.includes(".com")) {
      toast.warn("Invalid email Format");
      setError(true);
    } else {
      setError(false);
    }
  };

  const validateNoWork = () => {
    if (noOfWork.includes("-")) {
      toast.error("Invalid format! Please enter a positive number");
      setNoWorkError(true);
    } else if (!noOfWork.includes("-") && noOfWork !== "") {
      setNoWorkError(false);
    } else if (noOfWork === "") {
      setNoWorkError(true);
    }
  };

  // speeddial items
  const actions = [
    { icon: <EditIcon />, name: "Edit", action: toggleDialog },
    { icon: <DeleteIcon />, name: "Delete", action: ToggleModal },
  ];

  const CustomDial = styled(SpeedDial)({
    "& .MuiSpeedDial-fab": {
      backgroundColor: orgColor,
    },
  });

  // calculate available work
  useEffect(() => {
    if (workers?.length > 0) {
      let total = 0;
      workers.forEach((w: WorkerType) => {
        if (w.status !== "CANCEL") {
          total += 1;
        }
      });
      setAvailableWork(Number(data?.noOfWork) - total);
    }
  }, [workers]);

  return (
    <AdminPage
      heading={
        data?.id ? `Organization ID: ${data?.id}` : "Organization Details"
      }
    >
      <Grid container spacing={1} sx={{ padding: 2 }}>
        {/* headline */}
        <Grid item xs={12} md={8} sx={{ textAlign: "left", lineHeight: 0.5 }}>
          {loading ? (
            <Skeleton variant="rectangular" sx={{ height: "100%" }} />
          ) : (
            <Paper elevation={2} sx={{ height: "100%", padding: 2 }}>
              <h2>{data?.name}</h2>
              <h3>{data?.address}</h3>
              <div className={styles.detailsContainer}>
                <h5>Contact Details</h5>
                <div>
                  <strong>Mobile:</strong> {data?.mobile}
                </div>
                <div>
                  <strong>Email:</strong> {data?.email}
                </div>
              </div>
            </Paper>
          )}
        </Grid>
        {/* noOfwork */}
        <Grid item xs={12} md={4}>
          {loading ? (
            <Skeleton variant="rectangular" sx={{ minHeight: "30vh" }} />
          ) : (
            <Paper sx={{ height: "100%", padding: 2 }} elevation={2}>
              <div style={{ textAlign: "right", lineHeight: 0.5 }}>
                <h2>Number of Work: {data?.noOfWork}</h2>
                {data?.note !== "" && (
                  <h3 style={{ fontWeight: 400 }}>Note: {data?.note}</h3>
                )}
                {/* available work */}
                {workers?.length > 0 && (
                  <>
                    <h3 style={{ fontWeight: 400 }}>
                      Available Work: {availableWork}
                    </h3>
                  </>
                )}
              </div>
            </Paper>
          )}
        </Grid>
        {/* workers */}
        <Grid item xs={12} md={7}>
          <Paper elevation={elevation}>
            {workerLoading ? (
              <Skeleton variant="rectangular" sx={{ minHeight: "30vh" }} />
            ) : (
              <>
                {workers?.length > 0 ? (
                  <>
                    <div className={styles.workerContainer}>
                      <h2 style={{ margin: 0 }}>Workers</h2>
                      {/* workers component */}
                      <WorkersList data={workers} />
                    </div>
                    {/* @ts-ignore */}
                    <CSVLink
                      // hide the link underline
                      style={{ color: "transparent" }}
                      data={workers} //which data to print
                      // current date and time as file label
                      filename={`${String(new Date()).substring(0, 15)}-${
                        data?.name
                      }.csv`}
                    >
                      <Button variant="outlined" sx={{ margin: 2 }}>
                        Generate CSV
                      </Button>
                    </CSVLink>
                  </>
                ) : (
                  <h4 style={{ margin: 0 }}>No Workers found</h4>
                )}
              </>
            )}
          </Paper>
        </Grid>
        {/* speedidal */}
        {/* block edit/delete write for manager */}
        {user?.userEmail !== "manager@ramnaair.com" && (
          <CustomDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: "fixed", bottom: 26, right: 26 }}
            icon={<SpeedDialIcon />}
          >
            {actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={action.action}
              />
            ))}
          </CustomDial>
        )}
        {/* confirm delete modal */}
        <ConfirmModal
          open={open}
          setOpen={setOpen}
          confirmAction={deleteData}
          heading="Are you sure you want to delete this Organization?"
          subheading="This action is irreversible"
          clickLabel="Delete"
          confirmColor="error"
        />
        {/* update dialog, pass data, toggle, open state and edit function */}
        <UpdateOrg
          name={name}
          email={email}
          mobile={mobile}
          noOfWork={noOfWork}
          note={note}
          address={address}
          open={dialogOpen}
          onClose={toggleDialog}
          data={data}
          setName={setName}
          setEmail={setEmail}
          setMobile={setMobile}
          validateEmail={validateEmail}
          validateNoOfWork={validateNoWork}
          setAddress={setAddress}
          setNoOfWork={setNoOfWork}
          noWorkError={noWorkError}
          setNote={setNote}
          error={error}
          uploadData={updateData}
          backdrop={backdrop}
        />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdrop}
        >
          <CircularProgress />
        </Backdrop>
      </Grid>
    </AdminPage>
  );
};

export default OrgDetails;
