import React, {useState, useEffect, useContext} from "react";
import styles from "./styles.module.css";
import {useLocation, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

// react js pdf
import jsPDFInvoiceTemplate, {OutputType} from "jspdf-invoice-template";
// log
import ramna from "../../assets/ramna.png";

// firebase
import {doc, getDoc, getFirestore, deleteDoc} from "firebase/firestore";
import {getStorage, ref, deleteObject} from "firebase/storage";

// mui
import {Grid, Paper, Typography, Button} from "@mui/material";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import {styled} from "@mui/material";

// icons
import DeleteIcon from "@mui/icons-material/Delete";

// components
import AdminPage from "src/Components/AdminPage";
import {PaymentType} from "src/interfaces/Payments";
import {paymentsLink} from "src/constants/urls";
import ConfirmModal from "src/Components/ConfirmModal";
import {elevation} from "src/constants/elevation";
import ViewPdf from "src/Components/ViewPdf/ViewPdf";
import {paymentColor} from "../../constants/colors";
import {UserContext} from "../../context/UserContext";

const PaymentDetails: React.FC = () => {
  // @ts-ignore
  const {user} = useContext(UserContext);
  // grab location to grab paymentid
  const paymentId = useLocation()?.pathname?.split("/")[2];

  // states
  const [data, setData] = useState<PaymentType>();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false); //confirmation modal
  const [backdrop, setBackdrop] = useState(false); //for backdrop

  // hooks
  const db = getFirestore();
  const navigate = useNavigate();
  const storage = getStorage();

  // refs
  const paymentRef = doc(db, "payments", paymentId);
  const attachmentRef = ref(storage, `Payments/${data?.id}`);

  // grab data from firebase
  const grabData = async () => {
    try {
      const docSnap = await getDoc(paymentRef);

      if (docSnap.exists()) {
        // @ts-ignore
        const appendObj: PaymentType = {...docSnap.data(), id: docSnap.id};
        setData(appendObj);
      } else {
        toast.error("No Agent Found!");
      }
      // stop loading
      setLoading(false);
    } catch (e) {
      toast.error("Something went wrong. Please reload the window");
    }
  };

  //   get user data
  useEffect(() => {
    grabData();

    // cleanup
    return () => {
      setData(undefined);
      setLoading(true);
      setOpen(false);
      setBackdrop(false);
    };
  }, []);

  // modal and dialog helper functions
  const ToggleModal = () => {
    setOpen(!open);
  };

  // firebase actions
  // step 1: delete attachment if exists
  const deleteImg = async () => {
    if (data?.attachment !== "") {
      // delete pass img
      deleteObject(attachmentRef).catch(error => {
        console.log(error);
      });
    }
  };

  // firebase actions
  const deleteData = async () => {
    ToggleModal();
    setBackdrop(true);
    // delete from firebase
    try {
      await deleteImg(); //delete attachment
      await deleteDoc(doc(db, "payments", paymentId));
      toast.info(`Payment with the ID ${data?.id} has been removed`);
      navigate(paymentsLink);
      setBackdrop(false);
    } catch (e) {
      toast.error("Something went wrong, Please try again");
      setBackdrop(false);
    }
  };

  // speeddial items
  const actions = [{icon: <DeleteIcon />, name: "Delete", action: ToggleModal}];

  const CustomDial = styled(SpeedDial)({
    "& .MuiSpeedDial-fab": {
      backgroundColor: paymentColor,
    },
  });

  // js pdf invoice props
  // pdf props
  const pdfProps = {
    outputType: OutputType.Save,
    returnJsPDFDocObject: true,
    fileName: new Date(),
    orientationLandscape: false,
    compress: true,
    logo: {
      src: ramna,
      width: 15, //aspect ratio = width/height
      height: 15,
    },
    business: {
      name: "Ramna Air International",
      address:
        "66-67 Kamalapur Road, 3rd Floor, Eastern Commercial Complex, Dhaka 1000, Bangladesh",
      phone: "Mobile: +8801712710500",
      email: "Email: ramnaairbd@yahoo.com",
      website: "Website: https://ramnaairbd.com/",
    },
    contact: {
      label: "Payment Invoice issued for:",
      name: data?.agent?.name,
      address: `Agent ID: ${data?.agent?.id}`,
      phone: `Mobile: ${data?.agent?.mobile}`,
      email: `Email ${data?.agent?.email}`,
      otherInfo: `Company: ${data?.agent?.company} `,
    },
    invoice: {
      label: "Payment ID: #",
      num: `${data?.id}`,
      invDate: `Payment Date: ${data?.date?.substring(0, 25)} (24HRS Format)`,
      invGenDate: `Invoice Generation Date: ${String(new Date())?.substring(
        0,
        25
      )} (24HRS Format)`,
      headerBorder: true,
      tableBodyBorder: true,
      header: [
        {title: "Paid by"},
        {title: "Paid for"},
        {title: "Worker Pass No."},
        {title: "Method"},
        {title: "Due for Worker"},
      ],
      table: Array.from(Array(1), (item, index) => [
        data?.agent?.name,
        data?.worker?.name,
        data?.worker?.currentPass,
        data?.method,
        data?.worker?.agreedAmount,
      ]),
      additionalRows: [
        {
          col1: "Total:",
          col2: data?.amount?.toLocaleString(),
          style: {
            fontSize: 14, //optional, default 12
          },
        },
      ],
      invDescLabel: "_____________",
      invDesc: "Admin's Signature",
    },
    footer: {
      text: "Recruiting License no. 1172",
    },
    pageEnable: true,
    pageLabel: "Page ",
  };

  // generate pdf
  const generatePDF = () => {
    if (data) {
      // @ts-ignore
      jsPDFInvoiceTemplate(pdfProps);
    } else {
      toast.error("No Data Found");
    }
  };

  return (
    <AdminPage
      heading={data?.id ? `Payment ID: ${data?.id}` : "Payment Details"}
    >
      <>
        <Grid container spacing={1} sx={{padding: 2}}>
          {/* headline */}
          <Grid item xs={12} md={8} sx={{textAlign: "left", lineHeight: 0.5}}>
            {loading ? (
              <Skeleton variant="rectangular" sx={{height: "100%"}} />
            ) : (
              <Paper elevation={elevation} sx={{height: "100%", padding: 2}}>
                <Typography
                  variant="h5"
                  sx={{fontWeight: "bold", marginBottom: 5}}
                >
                  Paid by: {data?.agent?.name}
                </Typography>
                <Typography>
                  <strong>Paid for: </strong>
                  {data?.worker?.name}
                </Typography>
                <Typography>
                  <strong>Payment Date </strong>
                  {data?.date}
                </Typography>
              </Paper>
            )}
          </Grid>
          {/* balance */}
          <Grid item xs={12} md={4}>
            {loading ? (
              <Skeleton variant="rectangular" sx={{minHeight: "30vh"}} />
            ) : (
              <Paper sx={{height: "100%", padding: 2}} elevation={elevation}>
                <div style={{textAlign: "right"}}>
                  <Typography
                    variant="h4"
                    sx={{fontWeight: "bold", marginBottom: 5}}
                  >
                    ৳{data?.amount?.toLocaleString()}
                  </Typography>
                  <div>
                    <strong>Payment Method:</strong> {data?.method}
                  </div>
                  <div>
                    <strong>Paid to:</strong> {data?.createdBy}
                  </div>
                  {data?.remark !== "" && (
                    <h3 style={{fontWeight: 400}}>Remark: {data?.remark}</h3>
                  )}
                  <Button
                    sx={{marginTop: 2}}
                    fullWidth
                    variant="outlined"
                    onClick={generatePDF}
                  >
                    Generate Invoice
                  </Button>
                </div>
              </Paper>
            )}
          </Grid>
          {/* render attachment */}
          <Grid item xs={12} md={8}>
            <Paper elevation={elevation} sx={{height: "100%", padding: 2}}>
              <Typography variant="h5" sx={{fontWeight: "bold"}}>
                Attachment
              </Typography>
              {/* render attachment if exists */}
              {data?.fileType ? (
                <div>
                  {data?.fileType === "pdf" ? (
                    <div style={{marginTop: 10}}>
                      <ViewPdf file={data?.attachment} />
                      {/* open pdf in new window */}
                      <a
                        href={data?.attachment}
                        style={{textDecoration: "none"}}
                        target="href"
                      >
                        <Button variant="contained" sx={{marginTop: 5}}>
                          Download PDF
                        </Button>
                      </a>
                    </div>
                  ) : (
                    <a
                      href={data?.attachment}
                      style={{textDecoration: "none"}}
                      target="href"
                    >
                      <img
                        alt="image-attachment"
                        width="90%"
                        style={{marginTop: 15, borderRadius: 5}}
                        src={data?.attachment}
                      />
                    </a>
                  )}
                </div>
              ) : null}
              {data?.attachment === "" && <h3>No attachment found</h3>}
            </Paper>
          </Grid>
          {/* agent details */}
          <Grid item xs={12} md={4}>
            <Paper elevation={elevation} sx={{height: "100%", padding: 2}}>
              {/* agent details */}
              {data?.agent && (
                <div className={styles.agentDetails}>
                  <Typography
                    variant="h5"
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      marginBottom: 3,
                    }}
                  >
                    Agent Details
                  </Typography>
                  <Typography>Name: {data?.agent?.name}</Typography>
                  <Typography>Mobile: {data?.agent?.mobile}</Typography>
                  <Typography>Email: {data?.agent?.email}</Typography>
                  <Typography>Company: {data?.agent?.company}</Typography>
                  <Typography>Note: {data?.agent?.note}</Typography>
                  <Button
                    variant="outlined"
                    sx={{marginTop: 2}}
                    fullWidth
                    onClick={() => navigate(`/agents/${data?.agent?.id}`)}
                  >
                    See Agent
                  </Button>
                </div>
              )}
              {/* worker details */}
              {data?.worker && (
                <div className={styles.agentDetails}>
                  <Typography
                    variant="h5"
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      marginBottom: 3,
                      marginTop: 3,
                    }}
                  >
                    Worker Details
                  </Typography>
                  <Typography>Name: {data?.worker?.name}</Typography>
                  <Typography>Passport: {data?.worker?.currentPass}</Typography>
                  <Typography>Mobile: {data?.worker?.mobile}</Typography>
                  <Typography>
                    Agreed Amount: ৳
                    {data?.worker?.agreedAmount?.toLocaleString()}
                  </Typography>
                  <Button
                    variant="outlined"
                    fullWidth
                    sx={{marginTop: 2}}
                    onClick={() => navigate(`/workers/${data?.worker?.id}`)}
                  >
                    See Worker
                  </Button>
                </div>
              )}
            </Paper>
          </Grid>
        </Grid>
        {/* speedidal */}
        {user?.userEmail !== "admin@ramnaair.com" && (
          <CustomDial
            ariaLabel="SpeedDial basic example"
            sx={{position: "fixed", bottom: 26, right: 26}}
            icon={<SpeedDialIcon />}
          >
            {actions.map(action => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={action.action}
              />
            ))}
          </CustomDial>
        )}
        {/* confirm delete modal */}
        <ConfirmModal
          open={open}
          setOpen={setOpen}
          confirmAction={deleteData}
          heading="Are you sure you want to delete this Payment?"
          subheading="This action is irreversible"
          clickLabel="Delete"
          confirmColor="error"
        />
        <Backdrop
          sx={{color: "#fff", zIndex: theme => theme.zIndex.drawer + 1}}
          open={backdrop}
        >
          <CircularProgress />
        </Backdrop>
      </>
    </AdminPage>
  );
};

export default PaymentDetails;
