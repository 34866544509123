import React, {useState, useEffect, useContext} from "react";
import styles from "./styles.module.css";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

// firebase
import {
  collection,
  getFirestore,
  getDocs,
  query,
  where,
} from "firebase/firestore";

// mui
import {
  Grid,
  Paper,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Skeleton,
} from "@mui/material";

// icons
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";

// components
import AdminPage from "src/Components/AdminPage";
import {elevation} from "src/constants/elevation";
import {addOrgLink} from "src/constants/urls";
import {OrganizationType} from "src/interfaces/Organizations";
import {getOrgsQuery} from "src/utils/getOrgsQuery";
import {numOfPage} from "src/constants/numOfPage";
import OrgsTable from "./OrgTable";
import {UserContext} from "src/context/UserContext";

const Organizations: React.FC = () => {
  // @ts-ignore
  const {user} = useContext(UserContext);
  const [data, setData] = useState<OrganizationType[]>([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);

  // for pagination
  const [first, setFirst] = useState<string | null>(null); //grab the first obj for prev pagination
  const [last, setLast] = useState<string | null>(null); //grab the last obj name for next and prev pagination
  const [page, setPage] = useState(1); //check if it is the first page, where go back icon should not be visible on the table
  // search param
  const [filter, setFilter] = useState("name");
  // hooks
  const db = getFirestore();
  const navigate = useNavigate();

  // query obj for firebase
  const orgRef = collection(db, "organizations");

  const grabData = async () => {
    const q = getOrgsQuery(first, last, orgRef, numOfPage);

    try {
      const arr: OrganizationType[] = [];
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc: any) => {
        const data = doc.data();
        const appendObj = {...data, id: doc.id};
        // @ts-ignore
        arr.push(appendObj);
      });

      setData(arr);
      setLoading(false);
    } catch (e) {
      toast.error("Something went wrong. Please reload the window");
    }
  };

  // search for agent
  const searchOrgs = async () => {
    setLoading(true);
    try {
      // search using name
      const q = query(
        collection(db, "organizations"),
        where(filter, "==", search)
      );
      const arr: OrganizationType[] = [];

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc: any) => {
        const data = doc.data();
        const appendObj = {...data, id: doc.id};
        // @ts-ignore
        arr.push(appendObj);
      });
      // set search result to data
      setData(arr);
      setLoading(false);
    } catch (e) {
      toast.error("Something Went Wrong, Please try again!");
      setLoading(false);
    }
  };

  // search for orgs
  useEffect(() => {
    // if query is empty, grab default data
    if (search === "") {
      grabData();
    }
  }, [search]);

  // if first and last obj is changed by paginator, grab data
  useEffect(() => {
    grabData();
  }, [first, last]);

  // cleanup
  useEffect(() => {
    return () => {
      setData([]);
      setSearch("");
      setLoading(true);
      setFirst(null);
      setLast(null);
      setPage(1);
      setFilter("name");
    };
  }, []);

  // search filter
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setFilter(newAlignment);
  };

  return (
    <AdminPage heading="Organizations">
      <Grid sx={{padding: 1}} container spacing={1}>
        {/* search orgs */}
        <Grid item xs={12} sm={6}>
          <Paper elevation={elevation} sx={{padding: 2}}>
            <h2>Search for an Organization</h2>
            <div className={styles.filterContainer}>
              {/* filter */}
              <p>Searching using: </p>
              <ToggleButtonGroup
                color="primary"
                value={filter}
                exclusive
                onChange={handleChange}
              >
                <ToggleButton value="name">Name</ToggleButton>
                <ToggleButton value="mobile">Mobile</ToggleButton>
                <ToggleButton value="email">Email</ToggleButton>
              </ToggleButtonGroup>
            </div>
            <TextField
              label={`Enter Organization's ${filter}`}
              variant="outlined"
              fullWidth
              value={search}
              onChange={(t: React.ChangeEvent<HTMLInputElement>) =>
                setSearch(t.target.value)
              }
              // search on hit enter
              onKeyDown={(e: any) => {
                if (e.key === "Enter" || e.key === "Return") {
                  searchOrgs();
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={searchOrgs}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText={
                <p style={{color: "green"}}>
                  *Search is <strong>case sensitive</strong>
                </p>
              }
            />
          </Paper>
        </Grid>
        {/* add */}
        <Grid item xs={12} sm={6}>
          <Paper elevation={elevation} sx={{padding: 2, height: "100%"}}>
            <h2>Add an Organization</h2>
            {/* navigate to add agent page */}
            {/* block add for manager */}
            {user?.userEmail !== "manager@ramnaair.com" && (
              <Button
                endIcon={<AddIcon />}
                variant="outlined"
                onClick={() => navigate(addOrgLink)}
              >
                Add Organization
              </Button>
            )}
          </Paper>
        </Grid>
        {/* data table */}
        <Grid item xs={12} sx={{paddingBottom: 10}}>
          {loading ? (
            <div className={styles.skeletonContainer}>
              <Skeleton animation="wave" variant="rectangular" height={500} />
            </div>
          ) : (
            <Grid item xs={12}>
              {/* data, and pagelimit use state variables */}
              <OrgsTable
                data={data}
                setFirst={setFirst}
                setLast={setLast}
                setLoading={setLoading}
                page={page}
                setPage={setPage}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </AdminPage>
  );
};

export default Organizations;
