import styles from "./styles.module.css";
import { ServicesType } from "src/interfaces/ServicesType";

// mui
import { Card, CardContent, Typography, CardMedia, Grid } from "@mui/material";

// assets
import s1 from "../../assets/service-items/1.jpg";
import s2 from "../../assets/service-items/2.jpg";
import s3 from "../../assets/service-items/3.png";

const Services: React.FC = () => {
  const service: ServicesType[] = [
    {
      id: "1",
      name: "Worker Acquisition",
      desc: "Amongst thousands of workers, we find you the best suited employees for your business.",
      img: s1,
    },
    {
      id: "2",
      name: "Training",
      desc: "Providing training to the selected workers to perfectly meet the job demands.",
      img: s2,
    },
    {
      id: "3",
      name: "Follow Ups",
      desc: "After finding you the perfect worker, we will help both parties by maintaining constant communication to resolve any issues.",
      img: s3,
    },
  ];

  return (
    <div className={styles.mainContainer}>
      <h1>Our Services</h1>
      <Grid container spacing={3} className={styles.contentContainer}>
        {service?.map(({ id, name, desc, img }) => (
          <Grid key={id} item xs={12} sm={4}>
            <Card elevation={2} className={styles.cardItem}>
              <CardMedia component="img" height="240" image={img} alt={name} />
              <CardContent>
                <p className={styles.item}>{name}</p>
                <Typography variant="body2" color="text.secondary">
                  {desc}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Services;
