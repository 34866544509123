import {useEffect} from "react";
import {useLocation} from "react-router-dom";

// reset scroll position after route change
const ResetScroll = () => {
  const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ResetScroll;
