export const homeLink = "/";
export const aboutLink = "/about";
export const contactLink = "/contact";
export const loginLink = "/login";

// admin
export const dashboardLink = "/dashboard";

// agents
export const agentsLink = "/agents";
export const addAgentLink = "/agents/add-agent";
export const agentLink = "/agents/:id";

// orgs
export const orgLink = "/organizations";
export const addOrgLink = "/organizations/add-org";
export const orgDetailLink = "/organizations/:id";

// workers
export const workersLink = "/workers";
export const addWorkerLink = "/workers/add-worker";
export const editWorkerLink = "/workers/edit-worker/:id";
export const workerDetailsLink = "/workers/:id";

// payments
export const paymentsLink = "/payments";
export const addPaymentLink = "/payments/add-payment";
export const paymentDetailsLink = "/payments/:id";

// customer urls
export const customerUrls: { name: string; url: string }[] = [
  {
    name: "Home",
    url: homeLink,
  },
  {
    name: "About",
    url: aboutLink,
  },
  {
    name: "Contact Us",
    url: contactLink,
  },
];
