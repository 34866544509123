import React from "react";

// mui
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface IModalProps {
  open: boolean;
  setOpen: (args: boolean) => void;
  confirmAction: () => void;
  heading: string;
  subheading: string;
  clickLabel: string;
  confirmColor: "success" | "error" | "primary";
}

const ConfirmModal: React.FC<IModalProps> = ({
  open,
  setOpen,
  confirmAction,
  heading,
  subheading,
  clickLabel,
  confirmColor,
}) => {
  // toggle modal state
  const toggleModal = () => setOpen(!open);

  return (
    <Dialog open={open} onClose={toggleModal} sx={{textAlign: "center"}}>
      <DialogTitle>{heading}</DialogTitle>
      <DialogContent>
        <DialogContentText>{subheading}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleModal}>Cancel</Button>
        <Button color={confirmColor} onClick={confirmAction} autoFocus>
          {clickLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
