import styles from "./styles.module.css";

const Why: React.FC = () => {
  return (
    <div className={styles.container}>
      <h1>Why Recruit From Bangladesh?</h1>
      <div className={styles.block}>
        <blockquote>
          The geographical location and versatile demographics of the population
          makes Bangladesh a suitable source for manpower. Some of our biggest
          market is the Eastern Asia, Middle East and North Africa. Bangladeshi
          workers have hard earned the titles of “Honest” and “Hard-Workers”.
          More than 40% of our total population is young and capable of taking
          over any adventurous jobs anywhere in the world. There are both Govt.
          and Non-Govt drives to train these gigantic workforces and making them
          ready for the international market. We are proud to be part of the
          supply chain of this great workforce.
        </blockquote>
      </div>
    </div>
  );
};

export default Why;
