import React from "react";
import styles from "./styles.module.css";

// mui
import { Grid } from "@mui/material";

// components
import Page from "src/Components/Page";
import MapsComponent from "src/Components/MapsComponent";
import ContactDetails from "src/Components/ContactDetails";

// constants
import { RAMNA_BD_ADDRESS, RAMNA_MALAY_ADDRESS } from "src/constants/ramnaLink";

const Contact: React.FC = () => {
  return (
    <Page>
      <div className={styles.container}>
        <h1>Contact Us</h1>
        <Grid container spacing={2} sx={{ padding: 5, marginBottom: 10 }}>
          {/* bd */}
          <Grid item xs={12} md={6}>
            <MapsComponent
              link={RAMNA_BD_ADDRESS}
              label="Bangladeshi Address"
            />
          </Grid>
          {/* malay */}
          <Grid item xs={12} md={6}>
            <MapsComponent
              link={RAMNA_MALAY_ADDRESS}
              label="Malaysian Address"
            />
          </Grid>
          <Grid item xs={12}>
            <ContactDetails />
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};

export default Contact;
