import {
    query,
    limit,
    orderBy,
    startAfter,
    endBefore,
} from "firebase/firestore";


// determine the type of query, based on search params
export const getWorkersQuery = (first: string | null, last: string | null, workersRef: any, numOfPage: number) => {
    if (first) {
        return query(
            workersRef,
            orderBy("name"),
            endBefore(first),
            limit(numOfPage)
        );
    } else if (last) {
        return query(
            workersRef,
            orderBy("name"),
            startAfter(last),
            limit(numOfPage)
        );
    } else {
        return query(workersRef, orderBy("name"), limit(numOfPage));
    }
};