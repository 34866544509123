import React from "react";
import styles from "./styles.module.css";
import { Card, CardContent, Typography, CardActionArea } from "@mui/material";
import { motion } from "framer-motion";

// lottie
import Lottie from "lottie-react";
import anim1 from "../../assets/lottie/world3.json";
import anim2 from "../../assets/lottie/right2.json";
import anim3 from "../../assets/lottie/chart4.json";

interface featTypes {
  id: string;
  h: string;
  p: string;
  anim: any;
}

const Cards: React.FC = () => {
  const data: featTypes[] = [
    {
      id: "1",
      h: "Right Person For The Right Job",
      p: "We keep in touch with our clients as well as workers to ensure the best communication between both parties to create the best working relationship and environment",
      anim: anim2,
    },
    {
      id: "2",
      h: "Worldwide Reach",
      p: "At Ramna Air International, our mission is to provide our clients the very best workers. With that mission in mind, we always pick the right person for your business",
      anim: anim1,
    },
    {
      id: "3",
      h: "20+ Years of Experience",
      p: "in today's growing age, experience matters. Ramna has been the name of excellence for more than 2 decades and we keep on doing that by making decisions best for business",
      anim: anim3,
    },
  ];

  return (
    <motion.div className={styles.cardSelection}>
      <h1>Get Bagladeshi Workers for your company</h1>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {data.map((item: featTypes) => (
          <motion.div key={item.id} whileHover={{ scale: 1.1 }}>
            <Card
              sx={{
                maxWidth: 345,
                margin: 2,
                borderRadius: 2,
              }}
              elevation={2}
            >
              <CardActionArea
                style={{
                  minHeight: 200,
                  placeItems: "center",
                  display: "grid",
                }}
              >
                <Lottie
                  animationData={item.anim}
                  style={{ width: 70, padding: 10, height: 100 }}
                />
                <CardContent>
                  <Typography variant="h6" fontWeight={400}>
                    {item.h}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {item.p}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

export default Cards;
