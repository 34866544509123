import React from "react";
import {PaymentType} from "src/interfaces/Payments";
import {useNavigate} from "react-router-dom";
// mui
import {CardActionArea, styled} from "@mui/material";

interface IProps {
  data: PaymentType[];
}

const Card = styled(CardActionArea)({
  backgroundColor: "#ebebeb",
  display: "flex",
  justifyContent: "space-between",
  borderRadius: 12,
  padding: "10px 45px 10px 45px",
  alignItems: "center",
  fontWeight: 300,
  marginBottom: 8,
  ".avatar-container": {
    display: "flex",
    alignItems: "center",
    gap: 10,
    textAlign: "left",
  },
});

const PaymentList: React.FC<IProps> = ({data}) => {
  const navigate = useNavigate();

  return (
    <div style={{marginTop: 15}}>
      {data?.length > 0 ? (
        data?.map((i: PaymentType) => (
          <Card key={i?.id} onClick={() => navigate(`/payments/${i?.id}`)}>
            <div className="avatar-container">
              <span>
                <h3 style={{fontWeight: 400}}>ID: {i?.id}</h3>
                <h4>
                  <span style={{fontWeight: 300}}>Payment Agent:</span>{" "}
                  {i?.agent?.name}
                </h4>
              </span>
            </div>
            {/* agreed amount */}
            <div style={{textAlign: "right"}}>
              <h2>৳{i?.amount?.toLocaleString()}</h2>
              <p>
                Paid For: <strong>{i?.worker?.name}</strong>
              </p>
              <p>
                Date: <strong>{i?.date?.substring(0, 25)}</strong>
              </p>
            </div>
          </Card>
        ))
      ) : (
        <h3 style={{fontWeight: 400}}>No Payments found</h3>
      )}
    </div>
  );
};

export default PaymentList;
