import React from "react";
import {WorkerType} from "src/interfaces/Workers";
import {useNavigate} from "react-router-dom";
// components
import {
  primary,
  successColor,
  warningColor,
  errorColor,
} from "../../constants/colors";
// mui
import {Avatar, CardActionArea, styled} from "@mui/material";

interface IProps {
  data: WorkerType[];
}

const Card = styled(CardActionArea)({
  backgroundColor: "#ebebeb",
  display: "flex",
  justifyContent: "space-between",
  borderRadius: 12,
  padding: "10px 45px 10px 45px",
  alignItems: "center",
  fontWeight: 300,
  marginBottom: 8,
  ".avatar-container": {
    display: "flex",
    alignItems: "center",
    gap: 10,
    textAlign: "left",
  },
});

const WorkersList: React.FC<IProps> = ({data}) => {
  const navigate = useNavigate();
  return (
    <div style={{marginTop: 15}}>
      {data?.length > 0 ? (
        data?.map((i: WorkerType) => (
          <Card key={i?.id} onClick={() => navigate(`/workers/${i?.id}`)}>
            <div className="avatar-container">
              <Avatar
                variant="rounded"
                alt={i?.name}
                src={i?.passUrl}
                sx={{width: 56, height: 56, backgroundColor: primary}}
              />
              <span>
                <h3 style={{fontWeight: 400, marginBottom: 1}}>{i?.name}</h3>
                <h4 style={{margin: 0}}>Passport Number: {i?.currentPass}</h4>
                <p>
                  Medical Status:{" "}
                  <strong
                    style={{
                      color:
                        i?.medicalStatus === "FIT"
                          ? successColor
                          : i?.medicalStatus === "UNFIT"
                          ? errorColor
                          : warningColor,
                    }}
                  >
                    {i?.medicalStatus}
                  </strong>
                </p>
              </span>
            </div>
            {/* agreed amount */}
            <div style={{textAlign: "right"}}>
              <h2>৳{i?.agreedAmount.toLocaleString()}</h2>
              <p>
                Status: <strong>{i?.status}</strong>
              </p>
            </div>
          </Card>
        ))
      ) : (
        <h3 style={{fontWeight: 400}}>No Workers found</h3>
      )}
    </div>
  );
};

export default WorkersList;
