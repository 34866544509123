import React, { useState, useContext } from "react";
import styles from "./styles.module.css";
import { toast } from "react-toastify";
import { Navigate } from "react-router-dom";

// mui
import { Grid, Paper, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

// firebase
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

// context
import { UserContext } from "src/context/UserContext";

// components
import { dashboardLink } from "src/constants/urls";
import Page from "../../Components/Page";

const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  // grab user from context
  // @ts-ignore
  const { user } = useContext(UserContext);

  // hooks
  const auth = getAuth();

  // validate email address format
  const validateEmail = () => {
    if (!email.includes("@") || !email.includes(".com")) {
      toast.warn("Invalid email Format");
      setError(true);
    } else {
      setError(false);
    }
  };

  // firebase login function
  const login = () => {
    if (email === "" || password === "") {
      setError(true);
    } else {
      // reset error state
      setError(false);
      setLoading(true); //start loading spinner
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          toast.success("Hello Admin");
          // @ts-ignore
          const userToken = user?.accessToken; //grab token
          const userId = user?.uid; // grab id
          const userEmail = user?.email;
          const userDetails = { userToken, userId, userEmail }; // create obj that needs to be stored
          sessionStorage.setItem("user", JSON.stringify(userDetails)); //store to session storage
          setLoading(false); //stop loading spinner
          // reload window after 3s
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((error) => {
          // grab the error message
          const errorMessage = error.code;
          // display error message
          toast.error(errorMessage);
          setError(true);
          setLoading(false); //stop loading spinner
        });
    }
  };

  return (
    <Page>
      <div className={styles.container}>
        {/* if user exists, navigate to dashboard */}
        {user && <Navigate to={dashboardLink} replace={true} />}
        <Grid container>
          <Grid item xs={12} md={4} sx={{ margin: "auto" }}>
            <Paper
              elevation={3}
              sx={{ padding: 5, margin: "0px 25px 0px 25px" }}
            >
              <h1>Login</h1>
              <TextField
                id="email"
                label="Email"
                variant="outlined"
                fullWidth
                type="email"
                value={email}
                onChange={(t: React.ChangeEvent<HTMLInputElement>) =>
                  setEmail(t.target.value)
                }
                onBlur={validateEmail}
                error={error}
                helperText={
                  error ? "Please double check your email and password" : null
                }
              />
              <TextField
                id="password"
                label="Password"
                variant="outlined"
                fullWidth
                type="password"
                value={password}
                onChange={(t: React.ChangeEvent<HTMLInputElement>) =>
                  setPassword(t.target.value)
                }
                error={error}
                sx={{ marginTop: 2 }}
              />
              <LoadingButton
                fullWidth
                sx={{ marginTop: 2 }}
                onClick={login}
                variant="outlined"
                loading={loading}
              >
                Login
              </LoadingButton>
              <p style={{ color: "red" }}>⚠️ For internal use only! ⚠️</p>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};

export default Login;
