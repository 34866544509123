import React from "react";
import { AddPaymentFormType } from "./types";

// mui
import {
  Box,
  Grid,
  styled,
  Button,
  TextField,
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

// icons
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

// components
import { primary } from "../../constants/colors";
import { AgentType } from "src/Pages/Agents/types";
import { WorkerType } from "src/interfaces/Workers";
import { MethodType } from "src/interfaces/Payments";
import { AlignmentType } from "src/interfaces/Payments";
import ViewPdf from "../ViewPdf/ViewPdf";

const Input = styled("input")({
  display: "none",
});

const Divider = styled("div")({
  borderTop: `1px dashed ${primary}`,
  width: "70%",
  margin: "auto",
});

const AddPaymentForm: React.FC<AddPaymentFormType> = ({
  alignment,
  setAlignment,
  agentdata,
  date,
  setDate,
  agentLoading,
  workerData,
  workerLoading,
  error,
  setAgent,
  setWorker,
  note,
  setNote,
  method,
  setMethod,
  file,
  setFile,
  amount,
  setAmount,
  validateAmount,
  activeStep,
  skip,
  uploadData,
  uploadFile,
}) => {
  // whether image or pdf
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: AlignmentType
  ) => {
    setAlignment(newAlignment);
  };
  return (
    <div
      style={{
        minHeight: "92.5vh",
        padding: 15,
      }}
    >
      {/* stepper */}
      <Box>
        <Stepper
          sx={{ minWidth: { xs: "90vw", sm: "70vw" } }}
          activeStep={activeStep}
          orientation="vertical"
        >
          {/* form */}
          <Step key="1">
            <StepLabel>Enter Payment Details</StepLabel>
            {/* step body */}
            <StepContent>
              <Grid
                container
                spacing={2}
                sx={{ marginTop: 2, marginBottom: 2 }}
              >
                {/* agent */}
                <Grid item xs={12} lg={6}>
                  <Autocomplete
                    id="agent"
                    options={agentdata}
                    autoHighlight
                    getOptionLabel={option => option?.name}
                    loading={agentLoading}
                    onChange={(e: any, option: AgentType) => setAgent(option)}
                    renderOption={(props: any, a: AgentType) => (
                      <Box
                        onClick={(a: AgentType) => setAgent(a)}
                        key={a?.id}
                        component="li"
                        {...props}
                      >
                        {a?.name}
                      </Box>
                    )}
                    renderInput={params => (
                      <TextField
                        required
                        {...params}
                        label="Select Agent"
                        inputProps={{
                          ...params.inputProps,
                        }}
                        helperText="Select which agent is making the payment"
                      />
                    )}
                  />
                </Grid>
                {/* worker */}
                <Grid item xs={12} lg={6}>
                  <Autocomplete
                    id="worker"
                    options={workerData}
                    getOptionLabel={option =>
                      `${option?.name} (${option?.currentPass})`
                    }
                    loading={workerLoading}
                    onChange={(e: any, option: WorkerType) => setWorker(option)}
                    renderOption={(props: any, a: WorkerType) => (
                      <Box
                        onClick={(a: WorkerType) => setWorker(a)}
                        key={a?.id}
                        component="li"
                        {...props}
                      >
                        {`${a?.name} (${a?.currentPass})`}
                      </Box>
                    )}
                    renderInput={params => (
                      <TextField
                        required
                        {...params}
                        label="Select Worker"
                        inputProps={{
                          ...params.inputProps,
                        }}
                        helperText="Select a Worker under this agent"
                      />
                    )}
                  />
                </Grid>
                {/* date */}
                <Grid item xs={12} lg={6} sx={{ padding: 0.5 }}>
                  <DatePicker
                    openTo="day"
                    views={["year", "month", "day"]}
                    label="Date of Payment"
                    value={date}
                    onChange={(t: string | Date) => {
                      setDate(t?.toString());
                    }}
                    disableFuture
                    renderInput={params => (
                      <TextField
                        {...params}
                        helperText="mm/dd/yyyy"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                {/* status */}
                <Grid item xs={12} lg={6} sx={{ padding: 0.5 }}>
                  <FormControl fullWidth required>
                    <InputLabel id="status">Payment Method</InputLabel>
                    <Select
                      id="Payment Method"
                      value={method}
                      label="Payment Method"
                      onChange={(e: SelectChangeEvent) =>
                        setMethod(e.target.value as MethodType)
                      }
                    >
                      <MenuItem value="CASH">CASH</MenuItem>
                      <MenuItem value="BANK TRANSFER">BANK TRANSFER</MenuItem>
                      <MenuItem value="BANK CHEQUE">BANK CHEQUE</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {/* amount */}
                <Grid item xs={12} sx={{ padding: 0.5 }}>
                  <TextField
                    required
                    variant="outlined"
                    value={amount}
                    onChange={(t: React.ChangeEvent<HTMLInputElement>) =>
                      setAmount(t.target.value)
                    }
                    fullWidth
                    label="Amount"
                    type="number"
                    error={error}
                    onBlur={validateAmount}
                    helperText={
                      error &&
                      "Please ensure textfield only contains numerical value"
                    }
                  />
                </Grid>
                {/* remark */}
                <Grid item xs={12} sx={{ padding: 0.5 }}>
                  <TextField
                    variant="outlined"
                    value={note}
                    onChange={(t: React.ChangeEvent<HTMLInputElement>) =>
                      setNote(t.target.value)
                    }
                    fullWidth
                    label="Note"
                    multiline
                    rows={4}
                  />
                </Grid>
              </Grid>
              {/* upload and move to file section */}
              <Button variant="contained" fullWidth onClick={uploadData}>
                Create Payment
              </Button>
            </StepContent>
          </Step>
          {/* image */}
          <Step key="2">
            <StepLabel>Upload File (Optional)</StepLabel>
            {/* step body */}
            <StepContent>
              {/* upload image */}
              <Grid item xs={12} lg={4} sx={{ placeItems: "start center" }}>
                <Grid item xs={12}>
                  <h4>Select Image or PDF from local Machine</h4>
                  {/* toggle buttons */}
                  <ToggleButtonGroup
                    size="large"
                    sx={{ marginBottom: 15 }}
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                  >
                    <ToggleButton value="pdf">PDF</ToggleButton>
                    <ToggleButton value="image">Image</ToggleButton>
                  </ToggleButtonGroup>
                  {/* file picker */}
                  <br />
                  <label htmlFor="upload-file">
                    <Input
                      // conditionally accepted file format
                      accept={alignment === "pdf" ? ".pdf" : "image/*"}
                      id="upload-file"
                      multiple={false}
                      type="file"
                      // grab image blob to state
                      onChange={(event: any) => {
                        setFile(event.target.files[0]);
                      }}
                    />
                    <Button
                      endIcon={
                        alignment === "pdf" ? (
                          <UploadFileIcon />
                        ) : (
                          <CameraAltIcon />
                        )
                      }
                      variant="contained"
                      component="span"
                    >
                      {alignment === "pdf" ? "Select PDF File" : "Select Image"}
                    </Button>
                  </label>
                </Grid>
                {/* display */}
                <Grid item xs={12} sx={{ marginTop: 5 }}>
                  <Divider style={{ marginBottom: 20 }} />
                  {/* file */}
                  {alignment === "pdf" && file && <ViewPdf file={file} />}
                  {/* img */}
                  {alignment === "image" && file && (
                    <img
                      alt="worker-attachment"
                      width="70%"
                      style={{ marginTop: 15 }}
                      // @ts-ignore
                      src={URL.createObjectURL(file)}
                    />
                  )}
                </Grid>
                {/* go back to form */}
                <div style={{ marginTop: 20 }}>
                  <Button
                    sx={{ margin: 0.5 }}
                    variant="outlined"
                    onClick={skip}
                  >
                    Skip and Complete
                  </Button>
                  {/* <Button
                    sx={{margin: 0.5}}
                    variant="contained"
                    // TODO: generate pdf
                  >
                    Generate PDF of this Transaction
                  </Button> */}
                  <Button
                    sx={{ margin: 0.5 }}
                    variant="outlined"
                    onClick={uploadFile}
                  >
                    Upload and Complete
                  </Button>
                </div>
              </Grid>
            </StepContent>
          </Step>
        </Stepper>
      </Box>
    </div>
  );
};

export default AddPaymentForm;
