import React from "react";
import {IAddOrgFormTypes} from "./types";

// mui
import {Grid, TextField} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

const AddOrgForm: React.FC<IAddOrgFormTypes> = ({
  name,
  setName,
  email,
  setEmail,
  mobile,
  setMobile,
  address,
  setAddress,
  noOfWork,
  setNoOfWork,
  noWorkError,
  note,
  setNote,
  validateNoWork,
  uploadData,
  error,
  backdrop,
}) => {
  return (
    <>
      <Grid container spacing={2} sx={{padding: 4}}>
        {/* name */}
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            value={name}
            onChange={(t: React.ChangeEvent<HTMLInputElement>) =>
              setName(t.target.value)
            }
            fullWidth
            label="Organization Name"
            required
          />
        </Grid>
        {/* email */}
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            value={email}
            onChange={(t: React.ChangeEvent<HTMLInputElement>) =>
              setEmail(t.target.value)
            }
            fullWidth
            label="Email"
            type="email"
            error={error}
            helperText={
              error ? "Please double check email address format" : null
            }
          />
        </Grid>
        {/* mobile */}
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            value={mobile}
            onChange={(t: React.ChangeEvent<HTMLInputElement>) =>
              setMobile(t.target.value)
            }
            fullWidth
            label="Mobile"
            type="number"
          />
        </Grid>
        {/* company address */}
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            value={address}
            onChange={(t: React.ChangeEvent<HTMLInputElement>) =>
              setAddress(t.target.value)
            }
            fullWidth
            label="Company Address"
          />
        </Grid>
        {/* noOfWork */}
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            value={noOfWork}
            onChange={(t: React.ChangeEvent<HTMLInputElement>) =>
              setNoOfWork(t.target.value)
            }
            fullWidth
            label="Number of Work"
            type="number"
            required
            error={noWorkError}
            helperText={
              noWorkError
                ? "Invalid Number of work. it should not contain negative number and should not be empty"
                : null
            }
            onBlur={validateNoWork}
          />
        </Grid>
        {/* note */}
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            value={note}
            onChange={(t: React.ChangeEvent<HTMLInputElement>) =>
              setNote(t.target.value)
            }
            fullWidth
            label="Note"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            autoFocus
            loading={backdrop}
            onClick={uploadData}
            fullWidth
            variant="contained"
          >
            Save Organization
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
};

export default AddOrgForm;
