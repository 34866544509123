import styles from "./styles.module.css";

// packages
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

// mui
import { Grid } from "@mui/material";

// assets
import kam from "../../assets/kam.png";

// urls
import { customerUrls } from "src/constants/urls";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div style={{ paddingTop: 20 }} />
      <Grid container spacing={1}>
        {/* pages */}
        <Grid item xs={12} sm={6}>
          <h3 className={styles.title}>Pages</h3>
          <div>
            {customerUrls?.map(({ name, url }) => (
              <Link key={name} to={url} className={styles.linkItem}>
                <p className={styles.linkItemText}>{name}</p>
              </Link>
            ))}
          </div>
        </Grid>
        {/* address */}
        <Grid className={styles.contactInfo} item xs={12} sm={6}>
          <h3 className={styles.title}>Contact Us</h3>
          <div className={styles.contactContainer}>
            <p onClick={() => window.open("tel:+8801712710500")}>
              +880 1712 710500 (Bangladesh)
            </p>
            <p onClick={() => window.open("tel:+60102806114")}>
              +6010 280 6114 (Malaysia)
            </p>
            <p onClick={() => window.open("mailto:ramnaairbd@yahoo.com")}>
              ramnaairbd@yahoo.com
            </p>
          </div>
        </Grid>
        {/* cred */}
        <Grid
          sx={{
            borderTop: 1,
            borderBlockStartStyle: "dashed",
            marginTop: 5,
            borderRightStyle: { xs: "none", sm: "dashed" },
            borderRight: 0.5,
          }}
          item
          xs={12}
          sm={6}
        >
          <p>Developed By</p>
          <motion.img
            whileHover={{ scale: 1.1 }}
            onClick={() =>
              window.open("https://kamrul-islam.netlify.app/", "_blank")
            }
            src={kam}
            alt="kam"
            style={{ width: 80, cursor: "pointer" }}
          />
        </Grid>
        {/* rights */}
        <Grid
          sx={{ borderTop: 1, borderBlockStartStyle: "dashed", marginTop: 5 }}
          item
          xs={12}
          sm={6}
        >
          <p>
            All rights reserved © Ramna Air International {""}
            {new Date()?.getFullYear()}
          </p>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
