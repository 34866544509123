import React from "react";
import styles from "./styles.module.css";
import { motion } from "framer-motion";

// images
import atab from "../../assets/member/atab3.png";
import baira from "../../assets/member/baira.png";
import bd from "../../assets/member/bd.png";
import civil from "../../assets/member/civil.png";

const Creds: React.FC = () => {
  const logos = [atab, baira, bd, civil];

  return (
    <div className={styles.container}>
      <h1>Ramna Is Proudly A Member and In Compliance With</h1>
      {/* map through logos */}
      <div className={styles.compliance}>
        {logos.map((item, index) => (
          <motion.div
            whileHover={{ y: -25, borderRadius: 0 }}
            key={index.toString()}
            className={styles.credItem}
          >
            <img src={item} alt={item} style={{ width: 50 }} />
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Creds;
