import { createTheme } from '@mui/material/styles';
import { primary, secondary } from './colors';


export const theme = createTheme({
    palette: {
        primary: {
            // Purple and green play nicely together.
            main: primary,
        },
        secondary: {
            // This is green.A700 as hex.
            main: secondary,
        },
    },
});