import { Button, Paper } from "@mui/material";
import { Container } from "./ContactDetails.styles";

// icons
import MailIcon from "@mui/icons-material/Mail";
import CallIcon from "@mui/icons-material/Call";
import DirectionsIcon from "@mui/icons-material/Directions";

const ContactDetails = () => {
  return (
    <Container>
      <Paper className="paper">
        <ul style={{ textAlign: "left", lineHeight: 1.5 }}>
          <li>Mobile: +880 1712 710500 (Bangladesh)</li>
          <li>Malaysian Mobile: +6010 280 6114 (Malaysia)</li>
          <li>Email: ramnaairbd@yahoo.com</li>
          <li>
            Address: 66-67 Kamalapur Road, 3rd Floor, Eastern Commercial
            Complex, Dhaka 1000, Bangladesh
          </li>
          <li>
            Malaysian Address: No. A-1706, Level-17, Menara A, Persiaran MPAJ,
            Jalan Pandan Indah, 55100 Kuala Lumpur
          </li>
        </ul>
        {/* button */}
        <div className="button-container">
          <Button
            onClick={() => window.open("tel:+8801712710500")}
            variant="contained"
            style={{ margin: 2 }}
            endIcon={<CallIcon />}
          >
            Call Us
          </Button>
          <Button
            onClick={() => window.open("mailto:ramnaairbd@yahoo.com")}
            variant="contained"
            style={{ margin: 2 }}
            endIcon={<MailIcon />}
          >
            Email Us
          </Button>
          <Button
            onClick={() => window.open("https://goo.gl/maps/vqEDaPKq74CPVcrU6")}
            variant="contained"
            style={{ margin: 2 }}
            endIcon={<DirectionsIcon />}
          >
            Open on Google Maps
          </Button>
        </div>
      </Paper>
    </Container>
  );
};

export default ContactDetails;
