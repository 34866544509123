import React from "react";
import {IAddWorkerFormTypes} from "./types";
import {GenderType} from "src/interfaces/Genders";
import {WorkerStatusType} from "src/interfaces/WorkerStatus";
import {MedicalStatusType} from "src/interfaces/MedicalStatus";

// mui
import {Grid, TextField, Box, Button} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import LoadingButton from "@mui/lab/LoadingButton";
import Autocomplete from "@mui/material/Autocomplete";

// icons
import {AgentType} from "src/Pages/Agents/types";
import {OrganizationType} from "../../interfaces/Organizations";

const AddWorkerForm: React.FC<IAddWorkerFormTypes> = ({
  name,
  setName,
  fathersName,
  setFathersName,
  setAgentId,
  address,
  setAddress,
  agreedAmount,
  setAgreedAmount,
  currentPass,
  setCurrentPass,
  dob,
  setDob,
  gender,
  setGender,
  medicalCentre,
  setMedicalCentre,
  medicalStatus,
  setMedicalStatus,
  mobile,
  setMobile,
  note,
  setNote,
  setOrgId,
  status,
  setStatus,
  validateAmount,
  error,
  agreedAmountError,
  backdrop,
  uploadData,
  agentData,
  agentLoading,
  orgData,
  orgLoading,
  editMode,
  workerData,
  handleNext,
}) => {
  // grab current agent name
  const currentAgentObj = agentData.filter(
    (a: AgentType) => a.id === workerData?.agentId
  );
  const currentAgentName = currentAgentObj[0]?.name;

  const currentOrgObj = orgData.filter(
    (o: OrganizationType) => o.id === workerData?.orgId
  );
  const currentOrgName = currentOrgObj[0]?.name;
  console.log(currentOrgObj);

  return (
    <>
      <Grid container spacing={2}>
        {/* name */}
        <Grid item xs={12} lg={6} sx={{padding: 0.5}}>
          <TextField
            variant="outlined"
            value={name}
            onChange={(t: React.ChangeEvent<HTMLInputElement>) =>
              setName(t.target.value)
            }
            fullWidth
            label="Name"
            required
          />
        </Grid>
        {/* fathers name */}
        <Grid item xs={12} lg={6} sx={{padding: 0.5}}>
          <TextField
            variant="outlined"
            value={fathersName}
            onChange={(t: React.ChangeEvent<HTMLInputElement>) =>
              setFathersName(t.target.value)
            }
            fullWidth
            label="Father's Name"
          />
        </Grid>
        {/* mobile */}
        <Grid item xs={12} lg={6} sx={{padding: 0.5}}>
          <TextField
            variant="outlined"
            value={mobile}
            onChange={(t: React.ChangeEvent<HTMLInputElement>) =>
              setMobile(t.target.value)
            }
            fullWidth
            label="Mobile"
            type="number"
          />
        </Grid>
        {/* address */}
        <Grid item xs={12} lg={6} sx={{padding: 0.5}}>
          <TextField
            variant="outlined"
            value={address}
            onChange={(t: React.ChangeEvent<HTMLInputElement>) =>
              setAddress(t.target.value)
            }
            fullWidth
            label="Address"
          />
        </Grid>
        {/* dob */}
        <Grid item xs={12} lg={6} sx={{padding: 0.5}}>
          <DatePicker
            openTo="year"
            views={["year", "month", "day"]}
            label="Date of Birth"
            value={dob}
            onChange={(t: string | Date) => {
              setDob(t?.toString());
            }}
            disableFuture
            renderInput={params => (
              <TextField {...params} helperText="mm/dd/yyyy" fullWidth />
            )}
          />
        </Grid>
        {/* current passport */}
        <Grid item xs={12} lg={6} sx={{padding: 0.5}}>
          <TextField
            variant="outlined"
            value={currentPass}
            onChange={(t: React.ChangeEvent<HTMLInputElement>) =>
              setCurrentPass(t.target.value)
            }
            fullWidth
            label="Passport Number"
            required
          />
        </Grid>
        {/* Gender */}
        <Grid item xs={12} lg={6} sx={{padding: 0.5}}>
          <FormControl fullWidth>
            <InputLabel id="gender">Gender</InputLabel>
            <Select
              id="gender"
              value={gender}
              label="Gender"
              onChange={(e: SelectChangeEvent) =>
                setGender(e.target.value as GenderType)
              }
            >
              <MenuItem value="MALE">MALE</MenuItem>
              <MenuItem value="FEMALE">FEMALE</MenuItem>
              <MenuItem value="OTHER">OTHER</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* status */}
        <Grid item xs={12} lg={6} sx={{padding: 0.5}}>
          <FormControl fullWidth required>
            <InputLabel id="status">Worker Status</InputLabel>
            <Select
              id="status"
              value={status}
              label="Worker Status"
              onChange={(e: SelectChangeEvent) =>
                setStatus(e.target.value as WorkerStatusType)
              }
            >
              <MenuItem value="DATABASE">DATABASE</MenuItem>
              <MenuItem value="MEDICAL">MEDICAL</MenuItem>
              <MenuItem value="APPROVAL">APPROVAL</MenuItem>
              <MenuItem value="VISA">VISA</MenuItem>
              <MenuItem value="TICKET">TICKET</MenuItem>
              <MenuItem value="FLIGHT">FLIGHT</MenuItem>
              <MenuItem value="CANCEL">CANCEL</MenuItem>
              <MenuItem value="ON HOLD">ON HOLD</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* agent id */}
        <Grid item xs={12} lg={6} sx={{padding: 0.5}}>
          <Autocomplete
            id="agent"
            options={agentData}
            autoHighlight
            getOptionLabel={option => option?.name}
            loading={agentLoading}
            onChange={(e: any, option: AgentType) => setAgentId(option.id)}
            renderOption={(props: any, a: AgentType) => (
              <Box
                // set agent id
                onClick={() => setAgentId(a?.id)}
                key={a?.id}
                component="li"
                {...props}
              >
                {a?.name}
              </Box>
            )}
            renderInput={params => (
              <TextField
                required
                {...params}
                label="Select Agent"
                inputProps={{
                  ...params.inputProps,
                }}
                // for edits, display current agent
                helperText={
                  editMode &&
                  currentAgentName !== undefined && (
                    <span
                      style={{color: "black", fontWeight: 500}}
                    >{`Current Agent: ${currentAgentName}`}</span>
                  )
                }
              />
            )}
          />
        </Grid>
        {/* org */}
        <Grid item xs={12} lg={6} sx={{padding: 0.5}}>
          <Autocomplete
            id="organization"
            options={orgData}
            autoHighlight
            getOptionLabel={option => option?.name}
            loading={orgLoading}
            onChange={(e: any, option: OrganizationType) => setOrgId(option.id)}
            renderOption={(props: any, a: OrganizationType) => (
              <Box
                // set agent id
                onClick={() => setOrgId(a?.id)}
                key={a?.id}
                component="li"
                {...props}
              >
                {a?.name}
              </Box>
            )}
            renderInput={params => (
              <TextField
                {...params}
                label="Select Organization"
                inputProps={{
                  ...params.inputProps,
                }}
                required
                // for edits, display current agent
                helperText={
                  editMode &&
                  currentOrgName !== undefined && (
                    <span
                      style={{color: "black", fontWeight: 500}}
                    >{`Current Organization: ${currentOrgName}`}</span>
                  )
                }
              />
            )}
          />
        </Grid>
        {/* medical centre */}
        <Grid item xs={12} lg={6} sx={{padding: 0.5}}>
          <TextField
            variant="outlined"
            value={medicalCentre}
            onChange={(t: React.ChangeEvent<HTMLInputElement>) =>
              setMedicalCentre(t.target.value)
            }
            fullWidth
            label="Medical Centre"
          />
        </Grid>
        {/* medical status */}
        <Grid item xs={12} lg={6} sx={{padding: 0.5}}>
          <FormControl fullWidth required>
            <InputLabel id="medical-status">Medical Status</InputLabel>
            <Select
              id="medical-status"
              value={medicalStatus}
              label="Medical Status"
              onChange={(e: SelectChangeEvent) =>
                setMedicalStatus(e.target.value as MedicalStatusType)
              }
            >
              <MenuItem value="PENDING">PENDING</MenuItem>
              <MenuItem value="FIT">FIT</MenuItem>
              <MenuItem value="UNFIT">UNFIT</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* amount */}
        <Grid item xs={12} sx={{padding: 0.5}}>
          <TextField
            variant="outlined"
            value={agreedAmount}
            onChange={(t: React.ChangeEvent<HTMLInputElement>) =>
              setAgreedAmount(t.target.value)
            }
            fullWidth
            label="Amount"
            type="number"
            required
            error={agreedAmountError}
            helperText={
              agreedAmountError
                ? "Invalid Agreed Amount. it should not contain negative number and should not be empty"
                : null
            }
            onBlur={validateAmount}
          />
        </Grid>
        {/* note */}
        <Grid item xs={12} sx={{padding: 0.5}}>
          <TextField
            variant="outlined"
            value={note}
            onChange={(t: React.ChangeEvent<HTMLInputElement>) =>
              setNote(t.target.value)
            }
            fullWidth
            label="Note"
            multiline
            rows={4}
          />
        </Grid>
        {/* skip button */}
        {editMode && (
          <Grid item xs={12}>
            {/* button */}
            <Button onClick={handleNext} fullWidth variant="outlined">
              Skip Edit
            </Button>
          </Grid>
        )}
        {/* save/update button */}
        <Grid item xs={12}>
          {/* button */}
          <LoadingButton
            autoFocus
            loading={backdrop}
            onClick={uploadData}
            fullWidth
            variant="contained"
          >
            {editMode ? "Update Worker" : "Save Worker"}
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
};

export default AddWorkerForm;
