import React from "react";
import {agentTableProps, AgentType} from "src/Pages/Agents/types";
import {useNavigate} from "react-router-dom";

// mui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import {styled} from "@mui/material/styles";
import {Paper, IconButton} from "@mui/material";

// lottie
import Lottie from "lottie-react";
import animation from "../../assets/lottie/empty-anim.json";

// icons
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

// components
import {elevation} from "src/constants/elevation";
import {numOfPage} from "src/constants/numOfPage";

// custom row
const Row = styled(TableRow)({
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "lightgrey",
    cursor: "pointer",
  },
  "&:active": {
    backgroundColor: "grey",
  },
});

// custom footer for pagination
const Footer = styled(TableRow)({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  padding: 10,
  gap: 5,
  width: "100%",
  "& span": {
    fontStyle: "italic",
  },
});

// custom footer

const AgentTable: React.FC<agentTableProps> = ({
  data,
  setFirst,
  setLast,
  setLoading,
  page,
  setPage,
}) => {
  // hooks
  const navigate = useNavigate();

  const goBack = () => {
    setFirst(data[0]?.name);
    setLast(null); //clear last;
    setLoading(true);
    // decrease page
    setPage(page - 1);
  };
  const nextData = () => {
    setLast(data[9]?.name);
    // blank the first
    setFirst(null);
    setLoading(true);
    // increase page
    setPage(page + 1);
  };

  // pagination, upon clicking before, call grabdata function, first and last state determines who to search before and after
  return (
    <div>
      {data.length === 0 ? (
        <div style={{width: "50%", height: "50%", margin: "auto"}}>
          <Lottie
            animationData={animation}
            style={{width: "50%", height: "50%", margin: "auto"}}
          />
          <h1>No Data Found</h1>
        </div>
      ) : (
        <Paper
          elevation={elevation}
          sx={{
            maxWidth: "96vw",
            overflow: "scroll",
            textOverflow: "elipsis",
            whiteSpace: "nowrap",
          }}
        >
          <h2>Current Agents</h2>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell key="1">Name</TableCell>
                <TableCell key="2">Mobile</TableCell>
                <TableCell key="4">Company</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item: AgentType) => (
                <Row
                  key={item?.name}
                  onClick={() => navigate(`/agents/${item?.id}`)}
                >
                  <TableCell component="th" scope="row">
                    {item?.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item?.mobile}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item?.company}
                  </TableCell>
                </Row>
              ))}
              {/* pagination */}
            </TableBody>
          </Table>
          <Footer>
            <span>Rows Per Page: {numOfPage}</span>
            {/* prev, only display if not the inital page */}
            {page > 1 && (
              <IconButton onClick={goBack}>
                <ArrowBackIosNewIcon />
              </IconButton>
            )}
            {/* if data is less than num of Pages, dont show pagination icons  */}
            {data?.length >= numOfPage && (
              <IconButton onClick={nextData}>
                <ArrowForwardIosIcon />
              </IconButton>
            )}
          </Footer>
        </Paper>
      )}
    </div>
  );
};

export default AgentTable;
