import anim from "../../assets/lottie/around.json";
import Lottie from "lottie-react";
import styles from "./styles.module.css";

const ScrollLottie: React.FC = () => {
  const interactivity = {
    mode: "scroll",
    actions: [
      {
        visibility: [0.2, 0.9],
        type: "seek",
        frames: [20, 100],
      },
    ],
  };

  return (
    <div className={styles.container}>
      <Lottie
        className={styles.animation}
        animationData={anim}
        // @ts-ignore
        interactivity={interactivity}
      />
    </div>
  );
};

export default ScrollLottie;
