import React from "react";
import styles from "./styles.module.css";

const Banner: React.FC = () => {
  return (
    <div className={styles.hero}>
      <div className={styles.overlay} />
      <div className={styles.content}>
        <h1 className={styles.mainTitle}>Ramna Air International</h1>
        <h2 style={{marginTop: -13}}>Licensed Recruiting Agency</h2>
      </div>
    </div>
  );
};

export default Banner;
