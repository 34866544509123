import React, { useState, useEffect, useCallback } from "react";
import styles from "./styles.module.css";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ComposedChart,
  Line,
} from "recharts";

// mui
import { Grid, Paper, Typography, Skeleton } from "@mui/material";

// firestore
import {
  collection,
  getDocs,
  getFirestore,
  orderBy,
  query,
  limit,
} from "firebase/firestore";

// components
import AdminPage from "src/Components/AdminPage";

// constants
import { elevation } from "src/constants/elevation";
import { barColor } from "../../utils/barColor";
import { WorkerType } from "src/interfaces/Workers";
import { toast } from "react-toastify";
import { PaymentType } from "../../interfaces/Payments";
import { numOfPage } from "../../constants/numOfPage";

const Dashboard: React.FC = () => {
  const [workers, setWorkers] = useState<WorkerType[]>([]);
  const [workerLoading, setWorkerLoading] = useState(true);

  // last 10 payments
  const [payments, setPayments] = useState<PaymentType[]>([]);
  const [paymentLoading, setPaymentLoading] = useState(true);

  const [fitness, setFitness] = useState<
    {
      status: string;
      noOfWorkers: number;
    }[]
  >([]);

  const [statuses, setStatuses] = useState<
    {
      status: string;
      noOfWorkers: number;
    }[]
  >([]);

  // hooks
  const db = getFirestore();

  const grabWorkers = useCallback(async () => {
    const querySnapshot = await getDocs(collection(db, "workers"));
    try {
      const arr: WorkerType[] = [];
      querySnapshot.forEach((doc) => {
        const appendObj = { ...doc.data(), id: doc.id };
        // @ts-ignore
        arr.push(appendObj);
      });
      setWorkers(arr);
      setWorkerLoading(false);
    } catch {
      toast.error("Failed to retrieve workers");
      setWorkerLoading(false);
    }
  }, [db]);

  const grabPayments = useCallback(async () => {
    const q = query(
      collection(db, "payments"),
      orderBy("date"),
      limit(numOfPage)
    );
    const querySnapshot = await getDocs(q);
    try {
      const arr: PaymentType[] = [];
      querySnapshot.forEach((doc) => {
        const appendObj = { ...doc.data(), id: doc.id };
        // @ts-ignore
        arr.push(appendObj);
      });
      setPayments(arr);
      setPaymentLoading(false);
    } catch {
      toast.error("Failed to retrieve Payments");
      setPaymentLoading(false);
    }
  }, [db]);

  // grab workers and payments
  useEffect(() => {
    grabWorkers();
    grabPayments();

    // cleanup
    return () => {
      setWorkers([]);
      setWorkerLoading(true);
      setFitness([]);
      setStatuses([]);
      setPayments([]);
      setPaymentLoading(true);
    };
  }, [grabWorkers, grabPayments]);

  // grab max amount of agent repetaion in worker data
  useEffect(() => {
    if (workers?.length) {
      // grab agent repetition
      const count = {};

      // medical status'
      let fit = 0;
      let unfit = 0;
      let pending = 0;

      // statuses
      let database = 0;
      let medical = 0;
      let approval = 0;
      let cancel = 0;
      let flight = 0;
      let visa = 0;
      let ticket = 0;
      let onHold = 0;

      workers.forEach((x: WorkerType) => {
        // calculate agent rep
        // @ts-ignore
        count[x.agentId] = (count[x.agentId] || 0) + 1;

        // calculate fit
        if (x.medicalStatus === "PENDING") {
          pending += 1;
        } else if (x.medicalStatus === "FIT") {
          fit += 1;
        } else if (x.medicalStatus === "UNFIT") {
          unfit += 1;
        }

        // determine different db statuses
        if (x.status === "DATABASE") {
          database += 1;
        } else if (x.status === "MEDICAL") {
          medical += 1;
        } else if (x.status === "APPROVAL") {
          approval += 1;
        } else if (x.status === "CANCEL") {
          cancel += 1;
        } else if (x.status === "FLIGHT") {
          flight += 1;
        } else if (x.status === "VISA") {
          visa += 1;
        } else if (x.status === "TICKET") {
          ticket += 1;
        } else if (x.status === "ON HOLD") {
          onHold += 1;
        }
      });

      // check fit unfit obj
      const fitObj = { status: "FIT", noOfWorkers: fit };
      const unfitObj = { status: "UNFIT", noOfWorkers: unfit };
      const pendingObj = { status: "PENDING", noOfWorkers: pending };

      // status objs
      const databaseObj = {
        status: "DATABASE",
        noOfWorkers: database,
      };
      const medicalObj = {
        status: "MEDICAL",
        noOfWorkers: medical,
      };
      const approvalObj = {
        status: "APPROVAL",
        noOfWorkers: approval,
      };
      const cancelObj = {
        status: "CANCEL",
        noOfWorkers: cancel,
      };
      const flightObj = {
        status: "FLIGHT",
        noOfWorkers: flight,
      };
      const visaObj = {
        status: "VISA",
        noOfWorkers: visa,
        full: workers.length,
      };
      const ticketObj = {
        status: "TICKET",
        noOfWorkers: ticket,
      };
      const onHoldObj = {
        status: "ON HOLD",
        noOfWorkers: onHold,
      };

      // array with objs
      const fitnessArr = [fitObj, unfitObj, pendingObj];
      const statusArr = [
        databaseObj,
        medicalObj,
        approvalObj,
        cancelObj,
        flightObj,
        visaObj,
        ticketObj,
        onHoldObj,
      ];

      // grab these obj
      setFitness(fitnessArr);
      setStatuses(statusArr);
    }
  }, [workers]);

  return (
    <AdminPage heading="Dashboard">
      <Grid container spacing={2} sx={{ padding: 2 }}>
        {/* workers fitness */}
        <Grid item xs={12} lg={6}>
          {workerLoading ? (
            <div className={styles.skeletonContainer}>
              <Skeleton animation="wave" variant="rectangular" height={500} />
            </div>
          ) : (
            <Paper
              elevation={elevation}
              sx={{
                height: "100%",
                display: "grid",
                placeItems: "center",
                padding: 2,
              }}
            >
              <Typography variant="h5" sx={{ marginBottom: 5 }}>
                Medical Fitness
              </Typography>
              <ResponsiveContainer width="100%" height={500}>
                <BarChart
                  width={800}
                  height={300}
                  data={fitness}
                  margin={{
                    top: 5,
                    right: 5,
                    left: 5,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  {/* assign json element */}
                  <XAxis dataKey="status" />
                  <YAxis dataKey="noOfWorkers" />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="noOfWorkers" fill={barColor} />
                </BarChart>
              </ResponsiveContainer>
              <div className={styles.captionContainer}>
                <Typography variant="subtitle1">
                  No of Workers : {workers?.length}
                </Typography>
                <Typography variant="subtitle1">
                  Status/No of Workers
                </Typography>
              </div>
              <Typography variant="caption">
                Analysis of <i>Workers</i> in under different medical statuses
              </Typography>
            </Paper>
          )}
        </Grid>
        {/* Payments */}
        <Grid item xs={12} lg={6}>
          {paymentLoading ? (
            <div className={styles.skeletonContainer}>
              <Skeleton animation="wave" variant="rectangular" height={500} />
            </div>
          ) : (
            <Paper
              elevation={elevation}
              sx={{
                height: "100%",
                display: "grid",
                placeItems: "center",
                padding: 2,
              }}
            >
              <Typography variant="h5" sx={{ marginBottom: 5 }}>
                Payments
              </Typography>
              <ResponsiveContainer width="100%" height={500}>
                <ComposedChart
                  width={500}
                  height={400}
                  data={payments}
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  <CartesianGrid stroke="#f5f5f5" />
                  <XAxis dataKey="date" scale="band" />
                  <YAxis dataKey="amount" />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="amount" barSize={20} fill={barColor} />
                  <Line type="monotone" dataKey="amount" stroke="#ff7300" />
                </ComposedChart>
              </ResponsiveContainer>
              <div className={styles.captionContainer}>
                <Typography variant="subtitle1">
                  No of Payments : {payments?.length}
                </Typography>
                <Typography variant="subtitle1">Dates/Amounts</Typography>
              </div>
              <Typography variant="caption">
                Analysis of the last 10 <i>Payments</i>
              </Typography>
            </Paper>
          )}
        </Grid>
        {/* workers statuses */}
        <Grid item xs={12}>
          {workerLoading ? (
            <div className={styles.skeletonContainer}>
              <Skeleton animation="wave" variant="rectangular" height={500} />
            </div>
          ) : (
            <Paper
              elevation={elevation}
              sx={{
                height: "100%",
                display: "grid",
                placeItems: "center",
                padding: 2,
              }}
            >
              <Typography variant="h5" sx={{ marginBottom: 5 }}>
                Worker Status
              </Typography>
              <ResponsiveContainer width="100%" height={500}>
                <RadarChart cx="50%" cy="50%" outerRadius="80%" data={statuses}>
                  <PolarGrid />
                  <PolarAngleAxis dataKey="status" />
                  {/* start and end range */}
                  <PolarRadiusAxis angle={30} domain={[0, workers?.length]} />
                  <Radar
                    name="Number of Workers"
                    dataKey="noOfWorkers"
                    stroke={barColor}
                    fill={barColor}
                    fillOpacity={0.9}
                  />
                  <Legend />
                </RadarChart>
              </ResponsiveContainer>
              <div className={styles.captionContainer}>
                <Typography variant="subtitle1">
                  No of Workers : {workers?.length}
                </Typography>
                <Typography variant="subtitle1">
                  Status/No of Workers
                </Typography>
              </div>
              <Typography variant="caption">
                Analysis of <i>Workers</i> in under different medical statuses
              </Typography>
            </Paper>
          )}
        </Grid>
      </Grid>
    </AdminPage>
  );
};

export default Dashboard;
