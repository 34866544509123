import React, {useState, useContext} from "react";
import {Backdrop, CircularProgress} from "@mui/material";
import {useNavigate} from "react-router-dom";

// components
import AdminPage from "src/Components/AdminPage";
import {toast} from "react-toastify";
import {agentsLink} from "src/constants/urls";
import AddAgentForm from "src/Components/AddAgentForm";

// firebase
import {collection, getFirestore, addDoc} from "firebase/firestore";
import {UserContext} from "src/context/UserContext";

const AddAgent: React.FC = () => {
  // @ts-ignore
  const {user} = useContext(UserContext);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [company, setCompany] = useState("");
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);

  // hooks
  const db = getFirestore();
  const navigate = useNavigate();

  //  upload data to firestore
  const uploadData = () => {
    // dont upload if required field missing
    if (name === "" || mobile === "") {
      toast.error(
        "Please enter required fields and double check your entries!"
      );
    } else if (user?.userEmail === "manager@ramnaair.com") {
      toast.error("You do not have the rights to perform this operation");
    } else {
      setLoading(true); //start loading
      // Add a new document in collection "agents"
      addDoc(collection(db, "agents"), {
        name: name,
        company: company,
        mobile: mobile,
        note: note,
      })
        .then(() => {
          setLoading(false);
          toast.success(`${name} has been uploaded!`);
          // navigate back to agents
          navigate(agentsLink);
        })
        .catch(() => {
          toast.error("Something went wrong, Please try again");
          setLoading(false);
        });
    }
  };

  return (
    <AdminPage heading="Add an Agent">
      {/* pass required states to form */}
      <AddAgentForm
        name={name}
        setName={setName}
        mobile={mobile}
        setMobile={setMobile}
        company={company}
        setCompany={setCompany}
        note={note}
        setNote={setNote}
        uploadData={uploadData}
      />
      {/* backdrop for loading */}
      <Backdrop
        sx={{color: "#fff", zIndex: theme => theme.zIndex.drawer + 1}}
        open={loading}
      >
        <CircularProgress />
      </Backdrop>
    </AdminPage>
  );
};

export default AddAgent;
