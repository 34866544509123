import React from "react";

// components
import Banner from "src/Components/Banner";
import Cards from "src/Components/Cards";
import Why from "src/Components/Why";
import Services from "src/Components/OurServices";
import Clients from "src/Components/ClientsSlider";
import Creds from "src/Components/Creds";
import Industries from "src/Components/Industries/Industries";
import Guarantees from "src/Components/Guarantees/Guarantees";
import Inquiry from "src/Components/Inquiry";
import ScrollLottie from "src/Components/ScrollLottie";
import Page from "src/Components/Page";

const Home: React.FC = () => {
  return (
    <Page>
      <Banner />
      <Cards />
      <Clients />
      <Why />
      <Industries />
      <Services />
      <ScrollLottie />
      <Guarantees />
      <Creds />
      <Inquiry />
    </Page>
  );
};

export default Home;
