import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import { toast } from "react-toastify";

// mui
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

// colors
import {
  agentColor,
  primary,
  workerColor,
  paymentColor,
  orgColor,
} from "src/constants/colors";

// icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PeopleIcon from "@mui/icons-material/People";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import LogoutIcon from "@mui/icons-material/Logout";

// urls
import {
  loginLink,
  dashboardLink,
  agentsLink,
  orgLink,
  workersLink,
  paymentsLink,
} from "src/constants/urls";

// context
import { UserContext } from "src/context/UserContext";

// drawerWidth
const drawerWidth = 300;

export interface IAdminPageContainerProps {
  children?: React.ReactNode;
  heading?: string;
}

const AdminPage = ({ children, heading }: IAdminPageContainerProps) => {
  // @ts-ignore
  const { user } = useContext(UserContext);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [barColor, setBarColor] = useState(primary);

  // hooks
  const location = useLocation()?.pathname?.substring(1);
  const colorSegment = useLocation()?.pathname?.split("/")[1];
  const navigate = useNavigate();

  // grab bar color depending on position
  useEffect(() => {
    if (colorSegment === "agents") {
      setBarColor(agentColor);
    } else if (colorSegment === "workers") {
      setBarColor(workerColor);
    } else if (colorSegment === "payments") {
      setBarColor(paymentColor);
    } else if (colorSegment === "organizations") {
      setBarColor(orgColor);
    } else {
      setBarColor(primary);
    }
  }, [colorSegment]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  interface NavItemType {
    name: string;
    icon: React.ReactElement;
    link: string;
  }

  // nav items
  const NavItems: NavItemType[] = [
    { name: "dashboard", icon: <DashboardIcon />, link: dashboardLink },
    { name: "agents", icon: <AccountCircleIcon />, link: agentsLink },
    { name: "organizations", icon: <ApartmentIcon />, link: orgLink },
    { name: "workers", icon: <PeopleIcon />, link: workersLink },
    { name: "payments", icon: <MonetizationOnIcon />, link: paymentsLink },
  ];

  // logout function
  const logout = () => {
    if (!window.confirm("Are you sure you want to logout?")) return;

    // clear storage
    sessionStorage.removeItem("user");
    // navigate back to home page
    navigate("/");
    toast.info("You've logged out");
    
    // refresh window
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const drawer = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        paddingTop: 0,
      }}
    >
      <Toolbar
        sx={{ backgroundColor: barColor, color: "white", textAlign: "left" }}
      >
        <span>Logged in as: {user?.userEmail}</span>
      </Toolbar>
      <Divider />
      {/* nav section */}
      <List sx={{ flex: 3 }}>
        {NavItems.map((item) => (
          <ListItem
            key={item.name}
            disablePadding
            sx={{
              backgroundColor: location === item.name ? barColor : "white",
              color: location === item.name ? "white" : barColor,
              textTransform: "capitalize",
            }}
            onClick={() => navigate(item.link)}
          >
            <ListItemButton>
              <ListItemIcon
                sx={{ color: location === item.name ? "white" : barColor }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {/* logout */}
      <List>
        <ListItem
          disablePadding
          onClick={logout}
          sx={{
            backgroundColor: "white",
            color: barColor,
          }}
        >
          <ListItemButton>
            <ListItemIcon sx={{ color: barColor }}>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      {/* if user does not exists, navigate to login */}
      {!user && <Navigate to={loginLink} replace={true} />}
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: barColor,
        }}
      >
        <Toolbar sx={{ backgroundColor: barColor }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {heading}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      {/* body */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          padding: 0,
        }}
      >
        <Toolbar sx={{ backgroundColor: primary }} />
        {/* render children element */}
        <div style={{ minHeight: "70vh", overflowY: "hidden" }}>{children}</div>
      </Box>
    </Box>
  );
};

export default AdminPage;
