import React, { useState, useEffect, useContext, useCallback } from "react";
import styles from "./styles.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// react csv
import { CSVLink } from "react-csv";

// firebase
import {
  doc,
  getDoc,
  getFirestore,
  deleteDoc,
  setDoc,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";

// mui
import { Grid, Paper, styled, Button } from "@mui/material";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";

// icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

// components
import AdminPage from "src/Components/AdminPage";
import { AgentType } from "../Agents/types";
import ConfirmModal from "../../Components/ConfirmModal";
import UpdateAgent from "./UpdateAgent";
import { agentsLink } from "src/constants/urls";
import { elevation } from "../../constants/elevation";
import WorkersList from "../../Components/WorkersList";
import { WorkerType } from "src/interfaces/Workers";
import { PaymentType } from "src/interfaces/Payments";
import PaymentList from "../../Components/PaymentList/index";
import { agentColor } from "src/constants/colors";
import { UserContext } from "src/context/UserContext";

const AgentDetails: React.FC = () => {
  // @ts-ignore
  const { user } = useContext(UserContext);
  const [data, setData] = useState({} as AgentType);
  const [workers, setWorkers] = useState<WorkerType[]>([]);
  const [payments, setPayments] = useState<PaymentType[]>([]);
  const [loading, setLoading] = useState(true);
  const [workerLoading, setWorkerLoading] = useState(true);
  const [paymentLoading, setPaymentLoading] = useState(true);
  const [open, setOpen] = useState(false); //confirmation modal
  const [backdrop, setBackdrop] = useState(false); //for backdrop

  // calculations
  const [balance, setBalance] = useState(0); //dynamically calculate balance
  const [paid, setPaid] = useState(0);

  // dialog
  const [dialogOpen, setDialogOpen] = useState(false);

  // update details form
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [company, setCompany] = useState("");
  const [note, setNote] = useState("");

  // grab location to grab agentid
  const agentId = useLocation()?.pathname?.split("/")[2];

  // hooks
  const db = getFirestore();
  const navigate = useNavigate();

  // grab data from firebase
  const grabData = useCallback(async () => {
    const agentRef = doc(db, "agents", agentId);

    try {
      const docSnap = await getDoc(agentRef);

      if (docSnap.exists()) {
        const appendObj = { ...docSnap.data(), id: docSnap.id };
        // @ts-ignore
        setData(appendObj);
      } else {
        // doc.data() will be undefined in this case
        toast.error("No Agent Found!");
      }
      //   stop loading
      setLoading(false);
    } catch (e) {
      toast.error("Something went wrong. Please reload the window");
    }
  }, [agentId, db]);

  // grab all workers tied to this agent
  const grabWorkers = useCallback(async () => {
    const workerRef = collection(db, "workers");

    const q = query(workerRef, where("agentId", "==", agentId));
    try {
      const arr: WorkerType[] = [];
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc: any) => {
        const data = doc.data();
        const appendObj = { ...data, id: doc.id };
        // @ts-ignore
        arr.push(appendObj);
      });
      setWorkers(arr);
      setWorkerLoading(false);
    } catch (e) {
      toast.error("Something went wrong. Please reload the window");
    }
  }, [agentId, db]);

  // grab all workers tied to this agent
  const grabPayments = useCallback(async () => {
    const paymentRef = collection(db, "payments");

    const q = query(paymentRef, where("agent.id", "==", agentId));
    try {
      const arr: PaymentType[] = [];
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc: any) => {
        const data = doc.data();
        const appendObj = { ...data, id: doc.id };
        // @ts-ignore
        arr.push(appendObj);
      });
      arr.sort((a, b) => {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        // @ts-ignore
        return new Date(b.date) - new Date(a.date);
      });
      setPayments(arr);
      setPaymentLoading(false);
    } catch (e) {
      toast.error("Could not load payments. Please reload the window");
      setPaymentLoading(false);
    }
  }, [agentId, db]);

  // when data is updated modal, populate text fields
  useEffect(() => {
    setName(data?.name);
    setEmail(data?.email);
    setMobile(data?.mobile);
    setCompany(data?.company);
    setNote(data?.note);
  }, [data]);

  //   get user data
  useEffect(() => {
    grabData();
    grabWorkers();
    grabPayments();

    // cleanup
    return () => {
      setData({} as AgentType);
      setWorkers([]);
      setPayments([]);
      setLoading(true);
      setPaymentLoading(true);
      setOpen(false);
      setDialogOpen(false);
      setName("");
      setEmail("");
      setMobile("");
      setCompany("");
      setNote("");
      setBalance(0);
      setBackdrop(false);
      setWorkerLoading(true);
      setPaid(0);
    };
  }, [grabData, grabWorkers, grabPayments]);

  // calculate balance with not "database" and cancelled workers
  useEffect(() => {
    let initialAmount = 0;
    // add balance with workers
    workers.forEach((w: WorkerType) => {
      // only add if worker amount is no in db status
      if (w.status !== "DATABASE" && w.status !== "CANCEL") {
        initialAmount += Number(w.agreedAmount);
      }
    });
    setBalance(initialAmount);
  }, [workers]);

  // calculate total paid
  useEffect(() => {
    let initialAmount = 0;
    payments.forEach((w: PaymentType) => {
      initialAmount += Number(w?.amount);
    });
    setPaid(initialAmount);
  }, [payments]);

  // modal and dialog helper functions
  const ToggleModal = () => {
    setOpen(!open);
  };

  const toggleDialog = () => {
    setDialogOpen(!dialogOpen);
  };

  // //////

  // firebase actions
  const deleteData = async () => {
    if (user?.userEmail === "manager@ramnaair.com") {
      toast.error("You can't perform this operation");
    } else {
      ToggleModal();
      setBackdrop(true);
      // delete from firebase
      try {
        await deleteDoc(doc(db, "agents", agentId));
        toast.info(`${data?.name} has been removed`);
        navigate(agentsLink);
        setBackdrop(false);
      } catch (e) {
        toast.error("Something went wrong, Please try again");
        setBackdrop(false);
      }
    }
  };

  // update data
  const updateData = () => {
    if (user?.userEmail === "manager@ramnaair.com") {
      toast.error("You can't perform this operation");
    } else if (window.confirm(`Do you want to update ${name}`)) {
      setBackdrop(true);
      setDoc(doc(db, "agents", agentId), {
        name: name,
        email: email,
        mobile: mobile,
        company: company,
        note: note,
      })
        .then(() => {
          setBackdrop(false);
          toast.info(`${name} has been updated`);
          toggleDialog(); //close dialog
        })
        .then(() => grabData()) //get data again
        .catch(() => {
          toast.error("Something went wrong, please try again!");
          setBackdrop(false);
        });
    }
  };

  // speeddial items
  const actions = [
    { icon: <EditIcon />, name: "Edit", action: toggleDialog },
    { icon: <DeleteIcon />, name: "Delete", action: ToggleModal },
  ];

  const CustomDial = styled(SpeedDial)({
    "& .MuiSpeedDial-fab": {
      backgroundColor: agentColor,
    },
  });

  return (
    <AdminPage heading={data?.id ? `Agent ID: ${data?.id}` : "Agent Details"}>
      <Grid container spacing={1} sx={{ padding: 2 }}>
        {/* headline */}
        <Grid item xs={12} md={8} sx={{ textAlign: "left", lineHeight: 0.5 }}>
          {loading ? (
            <Skeleton variant="rectangular" sx={{ height: "100%" }} />
          ) : (
            <Paper elevation={2} sx={{ height: "100%", padding: 2 }}>
              <h2>{data?.name}</h2>
              <h3>{data?.company}</h3>
              <div className={styles.detailsContainer}>
                <h5>Contact Details</h5>
                <div>
                  <strong>Mobile:</strong> {data?.mobile}
                </div>
                <div>
                  <strong>Email:</strong> {data?.email}
                </div>
              </div>
            </Paper>
          )}
        </Grid>
        {/* balance */}
        <Grid item xs={12} md={4}>
          {loading ? (
            <Skeleton variant="rectangular" sx={{ minHeight: "30vh" }} />
          ) : (
            <Paper sx={{ height: "100%", padding: 2 }} elevation={2}>
              <div style={{ textAlign: "right" }}>
                <h2>Originally Due: ৳{balance?.toLocaleString()}</h2>
                <h3>Total Paid: ৳{paid?.toLocaleString()}</h3>
                <h3>
                  Remaining: ৳
                  {(Number(balance) - Number(paid))?.toLocaleString()}
                </h3>
                {data?.note !== "" && (
                  <h3 style={{ fontWeight: 400, paddingTop: 35 }}>
                    Note: {data?.note}
                  </h3>
                )}
                <span
                  style={{ fontSize: 13, fontStyle: "italic", color: "grey" }}
                >
                  *Workers with "DATABASE" & "CANCEL" status are not added
                  towards balance
                </span>
              </div>
            </Paper>
          )}
        </Grid>
        {/* workers */}
        <Grid item xs={12} md={6}>
          <Paper elevation={elevation}>
            {workerLoading ? (
              <Skeleton variant="rectangular" sx={{ minHeight: "30vh" }} />
            ) : (
              <div className={styles.workerContainer}>
                <h2 style={{ margin: 0 }}>Workers</h2>
                {/* workers component */}
                <WorkersList data={workers} />
                {workers?.length > 0 && (
                  // @ts-ignore
                  <CSVLink
                    // hide the link underline
                    style={{ color: "transparent" }}
                    data={workers} //which data to print
                    // current date and time as file label
                    filename={`${String(new Date()).substring(0, 15)}-${
                      data?.name
                    }.csv`}
                  >
                    <Button variant="outlined" sx={{ margin: 2 }}>
                      Generate CSV
                    </Button>
                  </CSVLink>
                )}
              </div>
            )}
          </Paper>
        </Grid>
        {/* payments */}
        <Grid item xs={12} md={6}>
          <Paper elevation={elevation} sx={{ height: "100%" }}>
            {paymentLoading ? (
              <Skeleton variant="rectangular" sx={{ minHeight: "30vh" }} />
            ) : (
              <div className={styles.workerContainer}>
                <h2 style={{ margin: 0 }}>Payments</h2>
                {/* payment component */}
                <PaymentList data={payments} />
                {payments?.length > 0 && (
                  // @ts-ignore
                  <CSVLink
                    // hide the link underline
                    style={{ color: "transparent" }}
                    data={payments} //which data to print
                    // current date and time as file label
                    filename={`${String(new Date()).substring(0, 15)}-${
                      data?.name
                    }.csv`}
                  >
                    <Button variant="outlined" sx={{ margin: 2 }}>
                      Generate CSV
                    </Button>
                  </CSVLink>
                )}
              </div>
            )}
          </Paper>
        </Grid>
      </Grid>
      {/* speedidal */}
      {/* block edit/delete for manager */}
      {user?.userEmail !== "manager@ramnaair.com" && (
        <CustomDial
          ariaLabel="SpeedDial basic example"
          sx={{ position: "fixed", bottom: 26, right: 26 }}
          icon={<SpeedDialIcon />}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.action}
            />
          ))}
        </CustomDial>
      )}
      {/* confirm delete modal */}
      <ConfirmModal
        open={open}
        setOpen={setOpen}
        confirmAction={deleteData}
        heading="Are you sure you want to delete this Agent?"
        subheading="This action is irreversible"
        clickLabel="Delete"
        confirmColor="error"
      />
      {/* update dialog, pass data, toggle, open state and edit function */}
      <UpdateAgent
        data={data}
        open={dialogOpen}
        onClose={toggleDialog}
        name={name}
        setName={setName}
        mobile={mobile}
        setMobile={setMobile}
        company={company}
        setCompany={setCompany}
        note={note}
        setNote={setNote}
        uploadData={updateData}
        backdrop={backdrop}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress />
      </Backdrop>
    </AdminPage>
  );
};

export default AgentDetails;
