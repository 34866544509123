import React, {useState, useContext} from "react";
import styles from "./styles.module.css";
import {Link, useLocation, useNavigate} from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import {toast} from "react-toastify";
import {motion} from "framer-motion";

// components
import {primary} from "../../constants/colors";

// assets
import logo from "../../assets/ramna.png";

// links
import {
  homeLink,
  aboutLink,
  loginLink,
  contactLink,
  dashboardLink,
} from "src/constants/urls";

// context
import {UserContext} from "src/context/UserContext";

const Topbar: React.FC = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null); //for admin options

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // @ts-ignore
  const {user} = useContext(UserContext); //grab current user

  // hooks
  const path = useLocation().pathname; //grab current location
  const navigate = useNavigate();

  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  // logout function
  const logout = () => {
    // clear storage
    sessionStorage.removeItem("user");
    // navigate back to home page
    navigate("/");
    toast.info("You've logged out");
    // refresh window
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  return (
    <AppBar
      position="fixed"
      elevation={3}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, .5)",
        backdropFilter: "blur(5)",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* pc logo */}
          <Container
            sx={{
              display: {xs: "none", sm: "flex"},
              flex: 3,
            }}
          >
            <Link to={homeLink}>
              <img className={styles.logo} src={logo} alt="ramna" />
            </Link>
          </Container>
          {/* mobile menu */}
          <Box
            sx={{
              display: {xs: "flex", sm: "none"},
              flex: 2,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="primary"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: {xs: "block", md: "none"},
              }}
            >
              <MenuItem key="1" onClick={handleCloseNavMenu}>
                <Link to={homeLink} style={{textDecoration: "none"}}>
                  <Typography textAlign="center">Home</Typography>
                </Link>
              </MenuItem>
              <MenuItem key="2" onClick={handleCloseNavMenu}>
                <Link to={aboutLink} style={{textDecoration: "none"}}>
                  <Typography textAlign="center">About</Typography>
                </Link>
              </MenuItem>
              <MenuItem key="3" onClick={handleCloseNavMenu}>
                <Link to={contactLink} style={{textDecoration: "none"}}>
                  <Typography textAlign="center">Contact Us</Typography>
                </Link>
              </MenuItem>
              {/* if logged in, show dashboard and logout */}
              {user ? (
                <>
                  <MenuItem
                    key="5"
                    onClick={() => {
                      handleCloseNavMenu();
                      navigate(dashboardLink);
                    }}
                  >
                    <Typography textAlign="center">Go to Dashboard</Typography>
                  </MenuItem>
                  <MenuItem
                    key="6"
                    onClick={() => {
                      handleCloseNavMenu();
                      logout();
                    }}
                  >
                    <Typography textAlign="center">Logout</Typography>
                  </MenuItem>
                </>
              ) : (
                <MenuItem key="4" onClick={handleCloseNavMenu}>
                  <Link to={loginLink} style={{textDecoration: "none"}}>
                    <Typography textAlign="center">Login</Typography>
                  </Link>
                </MenuItem>
              )}
            </Menu>
            {/* mobile logo */}
            <Link to={homeLink}>
              <Container
                sx={{
                  display: {xs: "flex", sm: "none"},
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <img className={styles.logo} src={logo} alt="ramna" />
              </Container>
            </Link>
          </Box>

          {/* pc meny */}
          {/* nav item */}
          <Container sx={{display: {xs: "none", sm: "flex"}, flex: 3}}>
            <Link
              className="link"
              to={homeLink}
              style={{textDecoration: "none"}}
            >
              {/* dont display button on smaller screen */}
              <Button
                sx={{
                  display: {
                    color: path === homeLink ? primary : "black",
                    borderBottom:
                      path === homeLink ? `3px solid ${primary}` : null,
                  },
                }}
              >
                <motion.div whileHover={{scale: 1.1}}>Home</motion.div>
              </Button>
            </Link>
            <Link to={aboutLink} style={{textDecoration: "none"}}>
              <Button
                sx={{
                  display: {
                    color: path === aboutLink ? primary : "black",
                    borderBottom:
                      path === aboutLink ? `3px solid ${primary}` : null,
                  },
                }}
              >
                <motion.div whileHover={{scale: 1.1}}>About</motion.div>
              </Button>
            </Link>
            <Link to={contactLink} style={{textDecoration: "none"}}>
              <Button
                sx={{
                  display: {
                    color: path === contactLink ? primary : "black",
                    borderBottom:
                      path === contactLink ? `3px solid ${primary}` : null,
                  },
                }}
              >
                <motion.div whileHover={{scale: 1.1}}>Contact</motion.div>
              </Button>
            </Link>
          </Container>
          {/* logout */}
          <Container sx={{display: {xs: "none", sm: "flex"}, flex: 1}}>
            {/* conditional button based on the user state */}
            {user ? (
              <div>
                <Button
                  id="admin-button"
                  onClick={handleClick}
                  variant="contained"
                >
                  Admin
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      // navigate to dashboard
                      navigate(dashboardLink);
                    }}
                  >
                    Go to Dashboard
                  </MenuItem>
                  {/* logout */}
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      logout();
                    }}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            ) : (
              <Link to={loginLink} style={{textDecoration: "none"}}>
                <Button variant="outlined">Login</Button>
              </Link>
            )}
          </Container>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Topbar;
