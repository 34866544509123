import { useState, useEffect } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

// icons
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";

// react toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// components
import { primary, secondary } from "./constants/colors";
import { theme } from "./constants/theme.ts";

// firebase
import { firebaseConfig } from "./api/fire";
import { initializeApp } from "firebase/app";

// context
import { UserContext } from "./context/UserContext";

// mui
import { ThemeProvider } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// urls
import {
  aboutLink,
  homeLink,
  contactLink,
  loginLink,
  dashboardLink,
  agentsLink,
  orgLink,
  workersLink,
  paymentsLink,
  addAgentLink,
  agentLink,
  addOrgLink,
  orgDetailLink,
  addWorkerLink,
  workerDetailsLink,
  editWorkerLink,
  addPaymentLink,
  paymentDetailsLink,
} from "./constants/urls";

// pages
import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/ContactUs";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import Agents from "./Pages/Agents";
import AddAgent from "./Pages/AddAgent";
import AgentDetails from "./Pages/AgentDetails/index";
import ResetScroll from "./Components/ResetScroll";
import Organizations from "./Pages/Organizations";
import AddOrgs from "./Pages/AddOrgs";
import OrgDetails from "./Pages/OrgDetails";
import Workers from "./Pages/Workers";
import AddWorker from "./Pages/AddWorker/index";
import WorkerDetails from "./Pages/WorkerDetails";
import Payments from "./Pages/Payments";
import AddPayment from "./Pages/AddPayment/index";
import PaymentDetails from "./Pages/PaymentDetails/index";
import NotFound from "./Pages/NotFound";

const App = () => {
  // to be passed to context
  const [user, setUser] = useState(null);

  // initialise firebase
  initializeApp(firebaseConfig);

  // check for user token in session storage
  useEffect(() => {
    const userData = sessionStorage.getItem("user");
    if (userData !== null && userData !== undefined) {
      setUser(JSON.parse(userData));
    }

    // cleanup
    return () => {
      setUser(null);
    };
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={theme}>
          <div className="App">
            <ToastContainer position="top-right" theme="dark" />
            <ScrollToTop
              style={{ backgroundColor: secondary }}
              smooth
              component={
                <ArrowCircleUpIcon
                  style={{ color: primary, backgroundColor: secondary }}
                />
              }
            />
            {/* reset scroll on route change */}
            <ResetScroll />
            <Routes>
              <Route path={homeLink} element={<Home />} />
              <Route path={aboutLink} element={<About />} />
              <Route path={contactLink} element={<Contact />} />
              <Route path={loginLink} element={<Login />} />
              {/* admin pages */}
              <Route path={dashboardLink} element={<Dashboard />} />
              {/* agents */}
              <Route path={agentsLink} element={<Agents />} />
              <Route path={addAgentLink} element={<AddAgent />} />
              <Route path={agentLink} element={<AgentDetails />} />
              {/* org */}
              <Route path={orgLink} element={<Organizations />} />
              <Route path={addOrgLink} element={<AddOrgs />} />
              <Route path={orgDetailLink} element={<OrgDetails />} />
              {/* workers */}
              <Route path={workersLink} element={<Workers />} />
              <Route path={addWorkerLink} element={<AddWorker />} />
              <Route path={editWorkerLink} element={<AddWorker />} />
              <Route path={workerDetailsLink} element={<WorkerDetails />} />
              {/* payments */}
              <Route path={paymentsLink} element={<Payments />} />
              <Route path={addPaymentLink} element={<AddPayment />} />
              <Route path={paymentDetailsLink} element={<PaymentDetails />} />
              {/* 404 */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </ThemeProvider>
      </LocalizationProvider>
    </UserContext.Provider>
  );
};

export default App;
