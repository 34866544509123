import React from "react";
import styles from "./styles.module.css";
import { Link } from "react-router-dom";

// mui
import Button from "@mui/material/Button";

const Inquiry: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.itemContainer}>
        <h1>Get in touch</h1>
        <h3 className={styles.subheading}>Opening Hours</h3>
        <ul className={styles.hours}>
          <li>Monday: 10am–6pm</li>
          <li>Tuesday: 10am–6pm</li>
          <li>Wednesday: 10am–6pm</li>
          <li>Thursday: 10am–6pm</li>
          <li>Friday: 10am–6pm</li>
          <li>Saturday: Closed</li>
          <li>Sunday: Closed</li>
        </ul>
        <Link to="/contact" style={{ textDecoration: "none" }}>
          <Button variant="contained">Contact Us</Button>
        </Link>
      </div>
    </div>
  );
};

export default Inquiry;
