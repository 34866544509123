import React, {useState, useContext} from "react";
import {Backdrop, CircularProgress} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

// firebase
import {collection, getFirestore, addDoc} from "firebase/firestore";

// components
import AdminPage from "src/Components/AdminPage";
import {orgLink} from "src/constants/urls";
import AddOrgForm from "src/Components/AddOrgForm";
import {UserContext} from "src/context/UserContext";

const AddOrgs: React.FC = () => {
  // @ts-ignore
  const {user} = useContext(UserContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [noOfWork, setNoOfWork] = useState("0");
  const [note, setNote] = useState("");

  // TODO: investigate if we even need this error state
  // @ts-ignore
  const [error, setError] = useState(false);
  const [noWorkError, setNoWorkError] = useState(false);

  const [loading, setLoading] = useState(false);

  // hooks
  const db = getFirestore();
  const navigate = useNavigate();

  const validateNoWork = () => {
    if (noOfWork.includes("-")) {
      toast.error("Invalid format! Please enter a positive number");
      setNoWorkError(true);
    } else if (!noOfWork.includes("-") && noOfWork !== "") {
      setNoWorkError(false);
    } else if (noOfWork === "") {
      setNoWorkError(true);
    }
  };

  //  upload data to firestore
  const uploadData = () => {
    // dont upload if required field missing
    if (name === "" || noOfWork === "" || noWorkError === true) {
      toast.error(
        "Please enter required fields and double check your entries!"
      );
    } else if (user?.userEmail === "manager@ramnaair.com") {
      toast.error("You can't perform this operation");
    } else {
      setLoading(true); //start loading
      // Add a new document in collection "organizations"
      addDoc(collection(db, "organizations"), {
        name: name,
        noOfWork: Number(noOfWork),
        email: email,
        mobile: mobile,
        note: note,
        address: address,
      })
        .then(() => {
          setLoading(false);
          toast.success(`${name} has been uploaded!`);
          // navigate back to agents
          setTimeout(() => {
            navigate(orgLink);
          }, 1000);
        })
        .catch(() => {
          toast.error("Something went wrong, Please try again");
          setLoading(false);
        });
    }
  };

  return (
    <AdminPage heading="Add an Organization">
      {/* pass required states to form */}
      <AddOrgForm
        name={name}
        setName={setName}
        email={email}
        setEmail={setEmail}
        mobile={mobile}
        setMobile={setMobile}
        address={address}
        setAddress={setAddress}
        noOfWork={noOfWork}
        setNoOfWork={setNoOfWork}
        note={note}
        setNote={setNote}
        error={error}
        noWorkError={noWorkError}
        validateNoWork={validateNoWork}
        uploadData={uploadData}
      />
      {/* backdrop for loading */}
      <Backdrop
        sx={{color: "#fff", zIndex: theme => theme.zIndex.drawer + 1}}
        open={loading}
      >
        <CircularProgress />
      </Backdrop>
    </AdminPage>
  );
};

export default AddOrgs;
