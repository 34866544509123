import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

// mui
import { Box, Grid, styled } from "@mui/material";
import { Backdrop, CircularProgress } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

// icons
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

// firebase
import {
  collection,
  getFirestore,
  addDoc,
  getDocs,
  query,
  updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

// components
import AdminPage from "src/Components/AdminPage";
import { workersLink } from "src/constants/urls";
import AddWorkerForm from "../../Components/AddWorkerForm/index";
import { GenderType } from "src/interfaces/Genders";
import { MedicalStatusType } from "src/interfaces/MedicalStatus";
import { WorkerStatusType } from "src/interfaces/WorkerStatus";
import { AgentType } from "../Agents/types";
import { OrganizationType } from "src/interfaces/Organizations";
import { FileType } from "src/interfaces/FileType";
import { primary } from "src/constants/colors";
import { WorkerType } from "../../interfaces/Workers";
import { AlignmentType } from "src/interfaces/Payments";
import ViewPdf from "../../Components/ViewPdf/ViewPdf";
import { UserContext } from "src/context/UserContext";

const Input = styled("input")({
  display: "none",
});

const Divider = styled("div")({
  borderTop: `1px dashed ${primary}`,
  width: "70%",
  margin: "auto",
});

const AddWorker: React.FC = () => {
  // @ts-ignore
  const { user } = useContext(UserContext);
  const [docId, setDocId] = useState("");
  const [agentId, setAgentId] = useState("");
  const [name, setName] = useState("");
  const [fathersName, setFathersName] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [dob, setDob] = useState(new Date().toString());
  const [gender, setGender] = useState<GenderType>("MALE");
  const [agreedAmount, setAgreedAmount] = useState("0");

  const [medicalCentre, setMedicalCentre] = useState("");
  const [medicalStatus, setMedicalStatus] =
    useState<MedicalStatusType>("PENDING");
  const [note, setNote] = useState("");

  const [currentPass, setCurrentPass] = useState("");
  const [orgId, setOrgId] = useState("");
  const [status, setStatus] = useState<WorkerStatusType>("DATABASE");

  // pass img
  const [passUrl, setPassUrl] = useState("");
  const [img, setImg] = useState<FileType>();

  // errorstates
  const [error, setError] = useState(false);
  const [agreedAmountError, setAgreedAmountError] = useState(false);

  // loaders
  const [loading, setLoading] = useState(false);
  const [backdrop, setBackdrop] = useState(false);

  // grab 10 orgs and agents for dropdown
  const [agentData, setAgentData] = useState<AgentType[]>([]);
  const [agentLoading, setAgentLoading] = useState(true);

  const [orgData, setOrgData] = useState<OrganizationType[]>([]);
  const [orgLoading, setOrgLoading] = useState(true);

  // for stepper
  const [activeStep, setActiveStep] = useState(0);

  // edits
  const [workerData, setWorkerData] = useState({} as WorkerType);
  const [editMode, setEditMode] = useState(false);

  // pdf or img
  const [alignment, setAlignment] = useState<AlignmentType>("pdf");

  // hooks
  const db = getFirestore();
  const storage = getStorage();
  const navigate = useNavigate();
  const workerParamId = useParams()?.id;

  // query obj for agents firebase
  const agentsRef = collection(db, "agents");
  const orgsRef = collection(db, "organizations");
  // @ts-ignore
  const storageRef = ref(storage, `Workers/${docId}`);

  // grab data from firebase
  const getAgents = async () => {
    const q = query(agentsRef);

    try {
      const arr: AgentType[] = [];
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc: any) => {
        const data = doc.data();
        const appendObj = { ...data, id: doc.id };
        // @ts-ignore
        arr.push(appendObj);
      });

      setAgentData(arr);
      setAgentLoading(false);
    } catch (e) {
      toast.error("Something went wrong. Please reload the window");
    }
  };

  const getOrgs = async () => {
    const q = query(orgsRef);

    try {
      const arr: OrganizationType[] = [];
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc: any) => {
        const data = doc.data();
        const appendObj = { ...data, id: doc.id };
        // @ts-ignore
        arr.push(appendObj);
      });

      setOrgData(arr);
      setOrgLoading(false);
    } catch (e) {
      toast.error("Something went wrong. Please reload the window");
    }
  };

  // grab worker details in case of edit
  const grabWorker = async () => {
    if (workerParamId) {
      const workerRef = doc(db, "workers", workerParamId);
      try {
        const docSnap = await getDoc(workerRef);

        if (docSnap.exists()) {
          const appendObj = { ...docSnap.data(), id: docSnap.id };
          // @ts-ignore
          setWorkerData(appendObj);
        } else {
          // doc.data() will be undefined in this case
          toast.error("No Organization Found!");
        }
        //   stop loading
        setLoading(false);
      } catch (e) {
        toast.error("Something went wrong. Please reload the window");
      }
    }
  };

  const validateAmount = () => {
    if (agreedAmount.includes("-")) {
      toast.error("Invalid format! Please enter a positive number");
      setAgreedAmountError(true);
    } else if (!agreedAmount.includes("-") && agreedAmount !== "") {
      setAgreedAmountError(false);
    } else if (agreedAmount === "") {
      setAgreedAmountError(true);
    }
  };

  //  upload data to firestore
  const uploadData = () => {
    // dont upload if required field missing
    if (
      name === "" ||
      currentPass === "" ||
      agreedAmount === "" ||
      agentId === "" ||
      orgId === "" ||
      error === true ||
      agreedAmountError === true
    ) {
      toast.error(
        "Please enter required fields and double check your entries!"
      );
    } else if (user?.userEmail === "manager@ramnaair.com") {
      toast.error("You can't perform this operation");
    } else {
      setLoading(true); //start loading
      // Add a new document in collection "organizations"
      addDoc(collection(db, "workers"), {
        address: address,
        agentId: agentId,
        agreedAmount: Number(agreedAmount),
        currentPass: currentPass,
        dob: dob,
        name: name,
        gender: gender,
        fathersName: fathersName,
        medicalCentre: medicalCentre,
        medicalStatus: medicalStatus,
        mobile: mobile,
        note: note,
        orgId: orgId,
        passUrl: passUrl,
        status: status,
      })
        .then(res => {
          // grab id for firestore
          setDocId(res?.id);
        })
        .then(() => {
          setLoading(false);
          toast.success(`${name} has been uploaded!`);
          handleNext(); //go to img section
        })
        .catch(() => {
          toast.error("Something went wrong, Please try again");
          setLoading(false);
        });
    }
  };

  // update data instead of upload if editing
  const updateData = () => {
    // dont upload if required field missing
    if (
      name === "" ||
      mobile === "" ||
      currentPass === "" ||
      agreedAmount === "" ||
      agentId === "" ||
      orgId === "" ||
      error === true ||
      agreedAmountError === true
    ) {
      toast.error(
        "Please enter required fields and double check your entries!"
      );
    } else if (user?.userEmail === "manager@ramnaair.com") {
      toast.error("You can't perform this operation");
    } else {
      setLoading(true); //start loading
      // Add a new document in collection "organizations"
      updateDoc(doc(db, "workers", docId), {
        address: address,
        agentId: agentId,
        agreedAmount: Number(agreedAmount),
        currentPass: currentPass,
        dob: dob,
        name: name,
        gender: gender,
        fathersName: fathersName,
        medicalCentre: medicalCentre,
        medicalStatus: medicalStatus,
        mobile: mobile,
        note: note,
        orgId: orgId,
        passUrl: passUrl,
        status: status,
      })
        .then(() => {
          setLoading(false);
          toast.success(`${name} has been updated!`);
          handleNext(); //go to img section
        })
        .catch(() => {
          toast.error("Something went wrong, Please try again");
          setLoading(false);
        });
    }
  };

  const uploadImg = () => {
    if (img) {
      setLoading(true);
      // 'file' comes from the Blob or File API
      // @ts-ignore
      uploadBytes(storageRef, img)
        .then(() => {
          // grab download url
          getDownloadURL(storageRef)
            .then(res => {
              // grab url to state
              updateImgUrl(res);
            })
            .catch(e => console.log(e));
          toast.success("File Uploaded successfully!");
        })
        .catch(e => {
          toast.error("Failed to upload file, please try again");
          console.log(e);
        });
    } else {
      toast.info("Please select an image first");
    }
  };

  // update the img url and naviagate to data page
  const updateImgUrl = (res: any) => {
    // for uploading image link
    updateDoc(doc(db, "workers", docId), {
      passUrl: res,
      fileType: alignment,
    })
      .then(() => {
        navigate(workersLink);
      })
      .catch(e => console.log(e));
  };

  // stepper functions
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const skip = () => {
    navigate(workersLink);
  };

  // grab agents and orgs
  useEffect(() => {
    getAgents();
    getOrgs();

    // if link is edit worker, populate fields and change uploadData function to updateFunction
    if (workerParamId) {
      grabWorker();
      setEditMode(true); //say we are in edit mode
    }

    return () => {
      setDocId("");
      setAgentId("");
      setName("");
      setFathersName("");
      setMobile("");
      setAddress("");
      setDob(new Date().toString());
      setGender("MALE");
      setAgreedAmount("0");
      setMedicalCentre("");
      setMedicalStatus("PENDING");
      setNote("");
      setCurrentPass("");
      setOrgId("");
      setStatus("DATABASE");
      setPassUrl("");
      // @ts-ignore
      setImg();
      setError(false);
      setAgreedAmountError(false);
      setLoading(false);
      setBackdrop(false);
      setAgentData([]);
      setAgentLoading(true);
      setOrgData([]);
      setOrgLoading(true);
      setActiveStep(0);
      // @ts-ignore
      setWorkerData({});
      // @ts-ignore
      setCurrentAgent({});
      // @ts-ignore
      setCurrentOrg({});
      setEditMode(false);
      setAlignment("pdf");
    };
  }, []);

  // after grabbing worker data, populate form
  useEffect(() => {
    if (Object.keys(workerData).length !== 0) {
      setDocId(workerData?.id);
      setAgentId(workerData?.agentId);
      setName(workerData?.name);
      setFathersName(workerData?.fathersName);
      setMobile(workerData?.mobile);
      setAddress(workerData?.address);
      setDob(workerData?.dob);
      setGender(workerData?.gender);
      setAgreedAmount(String(workerData?.agreedAmount));
      setMedicalCentre(workerData?.medicalCentre);
      setMedicalStatus(workerData?.medicalStatus);
      setNote(workerData?.note);
      setCurrentPass(workerData?.currentPass);
      setOrgId(workerData?.orgId);
      setStatus(workerData?.status);
      // @ts-ignore
      setPassUrl(workerData?.passUrl);
    }
  }, [workerData]);

  // whether image or pdf
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: AlignmentType
  ) => {
    setAlignment(newAlignment);
  };

  return (
    <AdminPage heading="Add a Worker">
      {/* backdrop for loading */}
      <div
        style={{
          minHeight: "92.5vh",
          padding: 15,
        }}
      >
        {/* stepper */}
        <Box>
          <Stepper
            sx={{ minWidth: { xs: "90vw", sm: "70vw" } }}
            activeStep={activeStep}
            orientation="vertical"
          >
            {/* form */}
            <Step key="1">
              <StepLabel>Enter Worker's Details</StepLabel>
              {/* step body */}
              <StepContent>
                <AddWorkerForm
                  setAgentId={setAgentId}
                  name={name}
                  setName={setName}
                  fathersName={fathersName}
                  setFathersName={setFathersName}
                  mobile={mobile}
                  setMobile={setMobile}
                  address={address}
                  setAddress={setAddress}
                  dob={dob}
                  setDob={setDob}
                  currentPass={currentPass}
                  setCurrentPass={setCurrentPass}
                  gender={gender}
                  setGender={setGender}
                  agreedAmount={agreedAmount}
                  setAgreedAmount={setAgreedAmount}
                  agreedAmountError={agreedAmountError}
                  setAgreedAmountError={setAgreedAmountError}
                  medicalCentre={medicalCentre}
                  setMedicalCentre={setMedicalCentre}
                  medicalStatus={medicalStatus}
                  setMedicalStatus={setMedicalStatus}
                  note={note}
                  setNote={setNote}
                  setOrgId={setOrgId}
                  status={status}
                  setStatus={setStatus}
                  validateAmount={validateAmount}
                  // if fresh, then uploadData function, eklse update data function
                  uploadData={editMode ? updateData : uploadData}
                  error={error}
                  backdrop={backdrop}
                  agentData={agentData}
                  agentLoading={agentLoading}
                  orgData={orgData}
                  orgLoading={orgLoading}
                  editMode={editMode}
                  workerData={workerData}
                  handleNext={handleNext}
                />
              </StepContent>
            </Step>
            {/* image */}
            <Step key="2">
              <StepLabel>Upload Passport Image (Optional)</StepLabel>
              {/* step body */}
              <StepContent>
                {/* upload image */}
                <Grid item xs={12} lg={4} sx={{ placeItems: "start center" }}>
                  <Grid item xs={12}>
                    <h4>Select Image or PDF from local Machine</h4>
                    {/* toggle buttons */}
                    <ToggleButtonGroup
                      size="large"
                      sx={{ marginBottom: 15 }}
                      color="primary"
                      value={alignment}
                      exclusive
                      onChange={handleChange}
                    >
                      <ToggleButton value="pdf">PDF</ToggleButton>
                      <ToggleButton value="image">Image</ToggleButton>
                    </ToggleButtonGroup>
                    {/* file picker */}
                    <br />
                    <label htmlFor="upload-img">
                      <Input
                        accept={alignment === "pdf" ? ".pdf" : "image/*"}
                        id="upload-img"
                        multiple={false}
                        type="file"
                        // grab image blob to state
                        onChange={(event: any) => {
                          setImg(event.target.files[0]);
                        }}
                      />
                      <Button
                        endIcon={
                          alignment === "pdf" ? (
                            <UploadFileIcon />
                          ) : (
                            <CameraAltIcon />
                          )
                        }
                        variant="contained"
                        component="span"
                      >
                        {alignment === "pdf"
                          ? "Select PDF File"
                          : "Select Image"}
                      </Button>
                      {/* <Button
                        endIcon={<PhotoCamera />}
                        variant="contained"
                        component="span"
                      >
                        Pick Image
                      </Button> */}
                    </label>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: 5 }}>
                    <Divider style={{ marginBottom: 20 }} />
                    {/* {passUrl === "" && <h4>No Image Found</h4>} */}
                    {/* show selected img */}
                    {/* file */}
                    {alignment === "pdf" && img && <ViewPdf file={img} />}
                    {/* img */}
                    {alignment === "image" && img && (
                      <img
                        alt="image-attachment"
                        width="70%"
                        style={{ marginTop: 15 }}
                        // @ts-ignore
                        src={URL.createObjectURL(img)}
                      />
                    )}
                  </Grid>
                  {/* go back to form */}
                  <div style={{ marginTop: 20 }}>
                    <Button
                      sx={{ margin: 0.5 }}
                      variant="outlined"
                      onClick={skip}
                    >
                      Skip and Complete
                    </Button>
                    <Button
                      sx={{ margin: 0.5 }}
                      variant="outlined"
                      onClick={uploadImg}
                    >
                      Upload and Complete
                    </Button>
                  </div>
                </Grid>
              </StepContent>
            </Step>
          </Stepper>
        </Box>
      </div>
      {/* backdrop loader */}
      <Backdrop
        sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress />
      </Backdrop>
    </AdminPage>
  );
};

export default AddWorker;
