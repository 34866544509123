import { Paper } from "@mui/material";
import { MapsContainer } from "./MapsComponent.styles";

export interface IMapsComponent {
  link: string;
  label: string;
}

const MapsComponent = ({ link, label }: IMapsComponent) => {
  return (
    <MapsContainer>
      <Paper className="iframe-container">
        <iframe src={link} title="map" loading="lazy" className="map" />
        <span className="label">{label}</span>
      </Paper>
    </MapsContainer>
  );
};

export default MapsComponent;
