import React from "react";
import styles from "./styles.module.css";

// carousel
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// logos
import everbest from "../../assets/clients/everbest.png";
import everspark from "../../assets/clients/everspark.png";
import gf from "../../assets/clients/gf.png";
import kb from "../../assets/clients/kb.png";
import losam from "../../assets/clients/losam.png";
import ql from "../../assets/clients/qlfoods-logo.png";
import yamada from "../../assets/clients/yamada.png";

const Clients: React.FC = () => {
  // responsive prop for react
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  // list of clients
  const clientArr: string[] = [everbest, everspark, gf, kb, losam, ql, yamada];

  return (
    <div className={styles.container}>
      <h1>Some of Our Clients</h1>
      <Carousel
        arrows={false}
        autoPlay
        infinite
        responsive={responsive}
        autoPlaySpeed={1000}
      >
        {clientArr?.map((c, index) => (
          <div
            key={index.toString()}
            style={{
              display: "grid",
              placeItems: "center",
              height: 300,
            }}
          >
            <img
              style={{ width: 150, pointerEvents: "none" }}
              src={c}
              alt={c}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Clients;
