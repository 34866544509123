import React, { useState, useEffect, useContext } from "react";
import styles from "./styles.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { WorkerType } from "../../interfaces/Workers";

// firebase
import {
  doc,
  getDoc,
  getFirestore,
  deleteDoc,
  query,
  where,
  collection,
  getDocs,
} from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";

// mui
import { Grid, Paper, Button, styled } from "@mui/material";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";

// icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// components
import AdminPage from "src/Components/AdminPage";
import ConfirmModal from "src/Components/ConfirmModal";
import { workersLink } from "src/constants/urls";
import { elevation } from "src/constants/elevation";
import { AgentType } from "../Agents/types";
import { OrganizationType } from "src/interfaces/Organizations";
import ViewPdf from "../../Components/ViewPdf/ViewPdf";
import { PaymentType } from "../../interfaces/Payments";
import PaymentList from "../../Components/PaymentList/index";
import { workerColor } from "../../constants/colors";
import { UserContext } from "src/context/UserContext";

const WorkerDetails: React.FC = () => {
  // @ts-ignore
  const { user } = useContext(UserContext);
  const [data, setData] = useState({} as WorkerType);
  const [agentdata, setAgentData] = useState({} as AgentType);
  const [orgData, setOrgData] = useState({} as OrganizationType);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false); //confirmation modal
  const [backdrop, setBackdrop] = useState(false); //for backdrop
  const [payments, setPayments] = useState<PaymentType[]>([]);
  const [paymentLoading, setPaymentLoading] = useState(true);
  const [paid, setPaid] = useState(0);

  // grab location to grab orgid
  const workerId = useLocation()?.pathname?.split("/")[2];

  //   hooks
  const db = getFirestore();
  const navigate = useNavigate();
  const storage = getStorage();

  //   doc ref
  const workerRef = doc(db, "workers", workerId);
  const passImgRef = ref(storage, `Workers/${data?.id}`);
  const paymentRef = collection(db, "payments");

  // modal and dialog helper functions
  const ToggleModal = () => {
    setOpen(!open);
  };

  //   TODO: navigate to add page with data
  const goToEdit = () => navigate(`/workers/edit-worker/${data.id}`);

  // grab data from firebase
  const grabData = async () => {
    try {
      const docSnap = await getDoc(workerRef);

      if (docSnap.exists()) {
        const appendObj = { ...docSnap.data(), id: docSnap.id };
        // @ts-ignore
        setData(appendObj);
      } else {
        // doc.data() will be undefined in this case
        toast.error("No Worker Found!");
      }
      //   stop loading
      setLoading(false);
    } catch (e) {
      toast.error("Something went wrong. Please reload the window");
    }
  };

  // grab agent id if exists
  const grabAgentData = async () => {
    if (data?.agentId) {
      const agentRef = doc(db, "agents", data?.agentId);
      try {
        const docSnap = await getDoc(agentRef);

        if (docSnap.exists()) {
          const appendObj = { ...docSnap.data(), id: docSnap.id };
          // @ts-ignore
          setAgentData(appendObj);
        } else {
          // doc.data() will be undefined in this case
          toast.info("No Agent found!");
        }
      } catch (e) {
        toast.error("Something went wrong. Please reload the window");
      }
    }
  };

  // grab all payments tied to this worker
  const grabPayments = async () => {
    // grab data from firebase
    const q = query(paymentRef, where("worker.id", "==", workerId));
    try {
      const arr: PaymentType[] = [];
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc: any) => {
        const data = doc.data();
        const appendObj = { ...data, id: doc.id };
        // @ts-ignore
        arr.push(appendObj);
      });
      arr.sort((a, b) => {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        // @ts-ignore
        return new Date(b.date) - new Date(a.date);
      });
      setPayments(arr);
      setPaymentLoading(false);
    } catch (e) {
      toast.error("Something went wrong. Please reload the window");
      setPaymentLoading(false);
    }
  };

  // grab agent id if exists
  const grabOrgData = async () => {
    if (data?.orgId) {
      const orgRef = doc(db, "organizations", data?.orgId);
      try {
        const docSnap = await getDoc(orgRef);

        if (docSnap.exists()) {
          const appendObj = { ...docSnap.data(), id: docSnap.id };
          // @ts-ignore
          setOrgData(appendObj);
        } else {
          toast.info("No Organization Found!");
        }
      } catch (e) {
        toast.error("Something went wrong. Please reload the window");
      }
    }
  };

  // firebase actions
  // step 1: delete image if exists
  const deleteImg = async () => {
    if (data?.passUrl !== "") {
      // delete pass img
      deleteObject(passImgRef).catch((error) => {
        console.log(error);
      });
    }
  };

  // step 4: delete doc
  const deleteData = async () => {
    ToggleModal();
    setBackdrop(true);
    // delete from firebase
    try {
      await deleteImg();
      await deleteDoc(doc(db, "workers", workerId));
      toast.info(`${data?.name} has been removed`);
      navigate(workersLink);
      setBackdrop(false);
    } catch (e) {
      toast.error("Something went wrong, Please try again");
      setBackdrop(false);
    }
  };

  // grab org and agent data if worker data is received
  useEffect(() => {
    grabAgentData();
    grabOrgData();
    grabPayments();
  }, [data]);

  // calculate paid amount
  useEffect(() => {
    let total = 0;
    if (payments.length) {
      payments.forEach((p: PaymentType) => {
        total += p.amount;
      });
      setPaid(total);
    }
  }, [payments]);

  // get user data
  useEffect(() => {
    grabData();

    return () => {
      setData({} as WorkerType);
      setLoading(true);
      setOpen(false);
      setBackdrop(false);
      // @ts-ignore
      setAgentData({});
      // @ts-ignore
      setOrgData({});
      setPayments([]);
      setPaymentLoading(true);
      setPaid(0);
    };
  }, []);

  // speeddial items
  const actions = [
    { icon: <EditIcon />, name: "Edit", action: goToEdit },
    { icon: <DeleteIcon />, name: "Delete", action: ToggleModal },
  ];

  const CustomDial = styled(SpeedDial)({
    "& .MuiSpeedDial-fab": {
      backgroundColor: workerColor,
    },
  });

  return (
    <AdminPage heading={data?.id ? `Worker ID: ${data?.id}` : "Worker Details"}>
      <Grid container spacing={1} sx={{ padding: 2 }}>
        {/* headline */}
        <Grid item xs={12} md={6} sx={{ textAlign: "left", lineHeight: 0.5 }}>
          {loading ? (
            <Skeleton variant="rectangular" sx={{ height: "100%" }} />
          ) : (
            <Paper elevation={elevation} sx={{ height: "100%", padding: 2 }}>
              <div>
                <h2 className={styles.title}>{data?.name}</h2>
                <h4>
                  Father's Name:{" "}
                  <span style={{ fontWeight: 400 }}>{data?.fathersName}</span>
                </h4>
                <div style={{ lineHeight: 0 }}>
                  <h4>
                    Date of Birth:{" "}
                    <span style={{ fontWeight: 400 }}>
                      {data?.dob.substring(0, 15)}
                    </span>
                  </h4>
                  <h4>
                    Gender:{" "}
                    <span style={{ fontWeight: 400 }}>{data?.gender}</span>
                  </h4>
                  <h4>
                    Current Passport Number:{" "}
                    <span style={{ fontWeight: 400 }}>{data?.currentPass}</span>
                  </h4>
                </div>
                <div className={styles.detailsContainer}>
                  <h5>Contact Details</h5>
                  <div style={{ marginTop: -15 }}>
                    <strong>Mobile:</strong> {data?.mobile}
                  </div>
                  <div>
                    <strong>Address:</strong> {data?.address}
                  </div>
                </div>
              </div>
            </Paper>
          )}
        </Grid>
        {/* agreed amount */}
        <Grid item xs={12} md={6}>
          {loading ? (
            <Skeleton variant="rectangular" sx={{ minHeight: "30vh" }} />
          ) : (
            <Paper sx={{ height: "100%", padding: 2 }} elevation={elevation}>
              <div className={styles.rightContainer}>
                <span style={{ lineHeight: 1 }}>
                  <h2>Amount Due: ৳{data?.agreedAmount.toLocaleString()}</h2>
                  <h3>Paid: ৳{paid?.toLocaleString()}</h3>
                </span>
                <div>
                  {/* note */}
                  {data?.note !== "" && (
                    <h4 style={{ fontWeight: 400 }}>Note: {data?.note}</h4>
                  )}
                  {/* medical status */}
                  <h4 style={{ fontWeight: 400 }}>
                    <strong>Status:</strong> {data?.status}
                  </h4>
                  {/* medical status */}
                  <h4 style={{ fontWeight: 400 }}>
                    <strong>Medical Status:</strong> {data?.medicalStatus}
                  </h4>
                  {/* nmedical centre */}
                  {data?.medicalCentre !== "" && (
                    <h4 style={{ fontWeight: 400 }}>
                      <strong>Medical Centre:</strong> {data?.medicalCentre}
                    </h4>
                  )}
                </div>
              </div>
            </Paper>
          )}
        </Grid>
        {/* agent data */}
        <Grid item xs={12} md={6}>
          {loading ? (
            <Skeleton variant="rectangular" sx={{ height: "100%" }} />
          ) : (
            <Paper elevation={elevation} sx={{ height: "100%", padding: 2 }}>
              {agentdata?.name && agentdata?.name !== "" ? (
                <div className={styles.agentContainer}>
                  <h2>Agent Details</h2>
                  <div style={{ marginTop: 10, lineHeight: 0.5 }}>
                    {/* name */}
                    {agentdata?.name && (
                      <h4 style={{ marginTop: 0 }}>
                        <strong>Agent Name:</strong> {agentdata?.name}
                      </h4>
                    )}
                    {/* compant */}
                    {agentdata?.company && (
                      <h4>
                        <strong>Agent's Company Name:</strong>{" "}
                        {agentdata?.company}
                      </h4>
                    )}
                  </div>
                  {/* contact details */}
                  <div className={styles.agentDetails}>
                    <h5>Contact Details</h5>
                    <h4 style={{ marginTop: 0 }}>
                      <strong>Mobile:</strong> {agentdata?.mobile}
                    </h4>
                    <h4 style={{ marginTop: 0 }}>
                      <strong>Email:</strong> {agentdata?.email}
                    </h4>
                  </div>
                  <Button
                    onClick={() => navigate(`/agents/${agentdata?.id}`)}
                    variant="outlined"
                    endIcon={<ArrowForwardIosIcon />}
                  >
                    Go to Agent
                  </Button>
                </div>
              ) : (
                <h2>No Agent details found</h2>
              )}
            </Paper>
          )}
        </Grid>
        {/* passport img */}
        <Grid item xs={12} md={6}>
          {loading ? (
            <Skeleton variant="rectangular" sx={{ height: "100%" }} />
          ) : (
            <Paper elevation={elevation} sx={{ height: "100%", padding: 2 }}>
              {data && data?.passUrl !== "" ? (
                <>
                  <h2 style={{ margin: 0 }}>Passport Attachment</h2>
                  <div>
                    {data?.fileType === "pdf" ? (
                      <div style={{ paddingTop: 10 }}>
                        <ViewPdf file={data?.passUrl} />
                        {/* open pdf in new window */}
                        <a
                          href={data?.passUrl}
                          style={{ textDecoration: "none" }}
                          target="href"
                        >
                          <Button variant="contained" sx={{ marginTop: 5 }}>
                            Download PDF
                          </Button>
                        </a>
                      </div>
                    ) : (
                      <a
                        href={data?.passUrl}
                        style={{ textDecoration: "none" }}
                        target="href"
                      >
                        <img
                          alt="image-attachment"
                          width="90%"
                          style={{ marginTop: 15, borderRadius: 5 }}
                          src={data?.passUrl}
                        />
                      </a>
                    )}
                  </div>
                </>
              ) : data?.passUrl === "" ? (
                <div
                  style={{
                    display: "grid",
                    placeItems: "center",
                    height: "100%",
                  }}
                >
                  <h3 style={{ margin: "auto" }}>No attachment found</h3>
                </div>
              ) : null}
            </Paper>
          )}
        </Grid>
        {/* company details */}
        <Grid item xs={12} md={4}>
          <Paper elevation={elevation} sx={{ height: "100%" }}>
            {loading ? (
              <Skeleton variant="rectangular" sx={{ minHeight: "30vh" }} />
            ) : (
              <div className={styles.companyContainer}>
                {orgData?.name && (
                  <h2 style={{ margin: 0 }}>Organization Details</h2>
                )}
                <h3>{orgData?.name}</h3>
                <h4>
                  <strong>Email: </strong>
                  {orgData?.email}
                </h4>
                <h4>
                  <strong>Mobile: </strong>
                  {orgData?.mobile}
                </h4>
                <h4>{orgData?.address}</h4>
                {orgData?.noOfWork && (
                  <h4>
                    <strong>No of Work: </strong>
                    {orgData?.noOfWork}
                  </h4>
                )}
                <Button
                  variant="outlined"
                  onClick={() => navigate(`/organizations/${orgData?.id}`)}
                  endIcon={<ArrowForwardIosIcon />}
                >
                  See organization details
                </Button>
              </div>
            )}
          </Paper>
        </Grid>
        {/* payments */}
        <Grid item xs={12} md={8}>
          <Paper elevation={elevation} sx={{ height: "100%" }}>
            {paymentLoading ? (
              <Skeleton variant="rectangular" sx={{ minHeight: "30vh" }} />
            ) : (
              <div className={styles.paymentsContainer}>
                <h2 style={{ margin: 0 }}>Payments under {data?.name}</h2>
                <PaymentList data={payments} />
              </div>
            )}
          </Paper>
        </Grid>
      </Grid>
      {/* confirm delete modal */}
      <ConfirmModal
        open={open}
        setOpen={setOpen}
        confirmAction={deleteData}
        heading={`Are you sure you want to remove ${data?.name}?`}
        subheading="This action is irreversible"
        clickLabel="Delete"
        confirmColor="error"
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress />
      </Backdrop>
      {/* speedidal */}
      {/* block edit or delete writes for manager */}
      {user?.userEmail !== "manager@ramnaair.com" && (
        <CustomDial
          ariaLabel="SpeedDial basic example"
          sx={{ position: "fixed", bottom: 26, right: 26 }}
          icon={<SpeedDialIcon />}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.action}
            />
          ))}
        </CustomDial>
      )}
    </AdminPage>
  );
};

export default WorkerDetails;
