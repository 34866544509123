import { createContext } from "react";

export interface UserObj {
  user: null;
  setUser: (a: any) => void;
}

// initial user state
// @ts-ignore
export const UserContext: UserObj = createContext(null);
