import React from "react";

// components
import Footer from "../Footer";
import Topbar from "../Topbar";

interface pageType {
  children?: React.ReactNode;
}

// for non admin pages
const Page: React.FC<pageType> = ({children}) => {
  return (
    <div>
      <Topbar />
      {children}
      <Footer />
    </div>
  );
};

export default Page;
