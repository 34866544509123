import styled from "@emotion/styled";

export const Container = styled.div`
  width: 100%;

  .paper {
    padding: 100px;
    margin-inline: auto;
    border-radius: 12px;
    background-color: #faf9f6;
  }
`;
