import React from "react";
import styles from "./styles.module.css";

const Guarantees: React.FC = () => {
  return (
    <div className={styles.container}>
      <h1>Our Guarantees</h1>
      <div className={styles.gList}>
        <ul className={styles.items}>
          <li>
            We will provide physically fit workers who will be medically checked
            before departure.
          </li>
          <li>
            After arriving on site, if any worker(s) is found medically unfit or
            involved in any strike or immoral activity, we will replace the
            staff member with another suitable candidate and Ramna will bear all
            costs. This offer is valid until the end of probationary period of
            minimum 90 days.
          </li>
          <li>
            Our representatives will pay regular visits to the workers, to
            monitor and ensure effective communication between employees and
            employers.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Guarantees;
