import React, {useState, useEffect, useContext} from "react";
import {Backdrop, CircularProgress} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {UserContext} from "../../context/UserContext";

// firebase
import {
  collection,
  getFirestore,
  getDocs,
  query,
  where,
  addDoc,
  updateDoc,
  doc,
} from "firebase/firestore";
import {getStorage, ref, uploadBytes, getDownloadURL} from "firebase/storage";

// components
import AdminPage from "src/Components/AdminPage";
import {toast} from "react-toastify";
import {AlignmentType, MethodType} from "src/interfaces/Payments";
import {AgentType} from "src/Pages/Agents/types";
import {WorkerType} from "src/interfaces/Workers";
import AddPaymentForm from "src/Components/AddPaymentForm";
import {FileType} from "src/interfaces/FileType";
import {paymentsLink} from "../../constants/urls";

const AddPayment: React.FC = () => {
  // @ts-ignore
  const {user} = useContext(UserContext); // grab user value from context
  const [paymentId, setPaymentId] = useState(""); //to upload img
  const [amount, setAmount] = useState("");
  const [date, setDate] = useState<string | Date>(new Date());
  const [attachment, setAttachment] = useState("");
  const [method, setMethod] = useState<MethodType>("CASH");
  const [note, setNote] = useState("");
  const [agent, setAgent] = useState<AgentType>();
  const [worker, setWorker] = useState<WorkerType>();

  const [alignment, setAlignment] = useState<AlignmentType>("pdf");

  //   filepicker
  const [file, setFile] = useState<Blob | FileType>();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  //   grab workers and agents
  const [agentdata, setAgentData] = useState<AgentType[]>([]);
  const [agentBalance, setAgentBalance] = useState(0);
  const [agentLoading, setAgentLoading] = useState(true);

  const [workerData, setWorkerData] = useState<WorkerType[]>([]);
  const [workerLoading, setWorkerLoading] = useState(true);

  // for stepper
  const [activeStep, setActiveStep] = useState(0);

  // hooks
  const db = getFirestore();
  const navigate = useNavigate();
  const storage = getStorage();

  // refs
  const agentsRef = collection(db, "agents");
  const workersRef = collection(db, "workers");
  // @ts-ignore
  const storageRef = ref(storage, `Payments/${paymentId}`);

  const validateAmount = () => {
    if (amount.includes("-")) {
      toast.error("Invalid format! Please enter a positive number");
      setError(true);
    } else if (!amount.includes("-") && amount !== "") {
      setError(false);
    } else if (amount === "") {
      setError(true);
    }
  };

  // grab data from firebase
  const getAgents = async () => {
    const q = query(agentsRef);

    try {
      const arr: AgentType[] = [];
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc: any) => {
        const data = doc.data();
        const appendObj = {...data, id: doc.id};
        // @ts-ignore
        arr.push(appendObj);
      });

      setAgentData(arr);
      setAgentLoading(false);
    } catch (e) {
      toast.error("Something went wrong. Please reload the window");
    }
  };

  // only grab workers under this agent
  const getWorkers = async () => {
    const q = query(workersRef, where("agentId", "==", agent?.id));

    try {
      const arr: WorkerType[] = [];
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc: any) => {
        const data = doc.data();
        const appendObj = {...data, id: doc.id};
        // @ts-ignore
        arr.push(appendObj);
      });

      setWorkerData(arr);
      setWorkerLoading(false);
    } catch (e) {
      toast.error("Something went wrong. Please reload the window");
    }
  };

  // once the agent is selected, grab all workers under this agent
  useEffect(() => {
    if (agent) {
      getWorkers();
      // calculate agent balance
      let total = 0;
      workerData.forEach((w: WorkerType) => {
        if (w.status !== "DATABASE") {
          total += Number(w.agreedAmount);
        }
      });
      setAgentBalance(total);
    }
  }, [agent, workerData]);

  // grab agents
  useEffect(() => {
    getAgents();

    // cleanup
    return () => {
      setAmount("");
      setDate(new Date());
      setAttachment("");
      setMethod("CASH");
      setNote("");
      setAgent(undefined);
      setWorker(undefined);
      setError(false);
      setLoading(false);
      setAgentData([]);
      setAgentLoading(true);
      setWorkerData([]);
      setWorkerLoading(true);
      setActiveStep(0);
      setAgentBalance(0);
      setPaymentId("");
      setFile(undefined);
      setAlignment("pdf");
    };
  }, []);

  // create firestore doc
  const uploadData = () => {
    // dont upload if required field missing
    if (amount === "" || error === true) {
      toast.error(
        "Please enter required fields and double check your entries!"
      );
    } else if (user?.userEmail === "admin@ramnaair.com") {
      toast.error("You can't perform this operation");
    } else {
      if (window.confirm("Create this payment?")) {
        setLoading(true); //start loading
        // Add a new document in collection "payments"
        addDoc(collection(db, "payments"), {
          agent: agent,
          amount: Number(amount),
          attachment: attachment,
          createdBy: user?.userEmail,
          // skip the timestamp, take only date
          date: String(date).substring(0, 15),
          method: method,
          remark: note,
          worker: worker,
        })
          .then(res => {
            // grab payment id
            setPaymentId(res?.id);
            setLoading(false);
            toast.success(`Payment has been created!`);
            handleNext(); // go to attachment section
          })
          .catch(() => {
            toast.error("Something went wrong, Please try again");
            setLoading(false);
          });
      }
    }
  };

  const uploadFile = () => {
    if (file) {
      setLoading(true);
      // 'file' comes from the Blob or File API
      // @ts-ignore
      uploadBytes(storageRef, file)
        .then(() => {
          // grab download url
          getDownloadURL(storageRef)
            .then(res => {
              // grab url to state
              updateImgUrl(res);
            })
            .catch(e => console.log(e));
          toast.success("File Uploaded successfully!");
        })
        .catch(e => {
          toast.error("Failed to upload File, please try again");
          console.log(e);
        });
    } else {
      toast.info("Please select an image or pdf first");
    }
  };

  // update the img url and naviagate to data page
  const updateImgUrl = (res: any) => {
    // for uploading image link
    updateDoc(doc(db, "payments", paymentId), {
      attachment: res,
      fileType: alignment,
    })
      .then(() => {
        navigate(paymentsLink);
      })
      .catch(e => console.log(e));
  };

  // stepper functions
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const skip = () => {
    navigate(paymentsLink);
  };

  return (
    <AdminPage heading="Add a Payment">
      {user?.userEmail === "admin@ramnaair.com" ? (
        <h1>You do not have access to these resources</h1>
      ) : (
        <>
          <AddPaymentForm
            alignment={alignment}
            setAlignment={setAlignment}
            amount={amount}
            method={method}
            note={note}
            agentBalance={agentBalance}
            date={date}
            setDate={setDate}
            setAmount={setAmount}
            setMethod={setMethod}
            setNote={setNote}
            setAgent={setAgent}
            setWorker={setWorker}
            file={file}
            setFile={setFile}
            error={error}
            agentdata={agentdata}
            workerData={workerData}
            validateAmount={validateAmount}
            agentLoading={agentLoading}
            workerLoading={workerLoading}
            activeStep={activeStep}
            skip={skip}
            uploadData={uploadData}
            uploadFile={uploadFile}
          />
          {/* backdrop for loading */}
          <Backdrop
            sx={{color: "#fff", zIndex: theme => theme.zIndex.drawer + 1}}
            open={loading}
          >
            <CircularProgress />
          </Backdrop>
        </>
      )}
    </AdminPage>
  );
};

export default AddPayment;
