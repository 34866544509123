import React, {useState, useEffect, useContext} from "react";
import styles from "./styles.module.css";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {UserContext} from "../../context/UserContext";

// firebase
import {
  collection,
  getFirestore,
  getDocs,
  getDoc,
  doc,
  query,
  where,
} from "firebase/firestore";

// mui
import {
  Grid,
  Paper,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Skeleton,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

// icons
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";

// components
import AdminPage from "../../Components/AdminPage/index";
import {elevation} from "../../constants/elevation";
import {PaymentType} from "../../interfaces/Payments";
import {numOfPage} from "../../constants/numOfPage";
import {getPaymentsQuery} from "../../utils/getPaymentsQuery";
import PaymentsTable from "./PaymentsTable";
import {addPaymentLink} from "../../constants/urls";

const Payments: React.FC = () => {
  // @ts-ignore
  const {user} = useContext(UserContext); // grab user value from context
  const [data, setData] = useState<PaymentType[]>([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  // for pagination
  const [first, setFirst] = useState<string | null>(null); //grab the first obj for prev pagination
  const [last, setLast] = useState<string | null>(null); //grab the last obj name for next and prev pagination
  const [page, setPage] = useState(1); //check if it is the first page, where go back icon should not be visible on the table
  // search param
  const [filter, setFilter] = useState<"id" | "agent.mobile" | "date">("id");
  // hooks
  const db = getFirestore();
  const navigate = useNavigate();

  // query obj for firebase
  const paymentsRef = collection(db, "payments");

  // grab data from firebase
  const grabData = async () => {
    const q = getPaymentsQuery(first, last, paymentsRef, numOfPage);

    try {
      const arr: PaymentType[] = [];
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc: any) => {
        const data = doc.data();
        const appendObj = {...data, id: doc.id};
        // @ts-ignore
        arr.push(appendObj);
      });
      arr.sort((a, b) => {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        // @ts-ignore
        return new Date(b.date) - new Date(a.date);
      });
      setData(arr);
      setLoading(false);
    } catch (e) {
      toast.error("Something went wrong. Please reload the window");
    }
  };

  // search for a payment using id
  const searchPayment = async () => {
    setLoading(true);
    try {
      const docSnap = await getDoc(doc(db, "payments", search));

      if (docSnap.exists()) {
        // @ts-ignore
        const appendObj: PaymentType = {...docSnap.data(), id: docSnap.id};
        // grab data to state
        setData([appendObj]);
      } else {
        toast.error("No Payment Found!");
        setData([]);
      }
      //   stop loading
      setLoading(false);
    } catch (e) {
      toast.error("Something went wrong. Please reload the window");
      setLoading(false);
    }
  };

  // search using mobile
  const searchFilter = async () => {
    setLoading(true);
    try {
      // search using name
      const q = query(collection(db, "payments"), where(filter, "==", search));
      const arr: PaymentType[] = [];

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc: any) => {
        const data = doc.data();
        const appendObj = {...data, id: doc.id};
        // @ts-ignore
        arr.push(appendObj);
      });
      // set search result to data
      setData(arr);
      setLoading(false);
    } catch (e) {
      toast.error("Something Went Wrong, Please try again!");
      setLoading(false);
    }
  };

  // if query is empty, grab default data
  useEffect(() => {
    if (search === "") {
      grabData();
    }
    console.log(search);
  }, [search]);

  // if first and last obj is changed by paginator, grab data
  useEffect(() => {
    grabData();
  }, [first, last]);

  // cleanup
  useEffect(() => {
    return () => {
      setData([]);
      setSearch("");
      setLoading(true);
      setFirst(null);
      setLast(null);
      setPage(1);
      setFilter("id");
    };
  }, []);

  // search filter
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: "id" | "agent.mobile" | "date"
  ) => {
    setFilter(newAlignment);
  };

  return (
    <AdminPage heading="Payments">
      <Grid sx={{padding: 1}} container spacing={1}>
        {/* search payments */}
        <Grid item xs={12} sm={6}>
          <Paper elevation={elevation} sx={{padding: 2}}>
            <h2>Search for a Payment</h2>
            <div className={styles.filterContainer}>
              {/* filter */}
              <p>Searching using: </p>
              <ToggleButtonGroup
                color="primary"
                value={filter}
                exclusive
                onChange={handleChange}
              >
                <ToggleButton value="id">ID</ToggleButton>
                <ToggleButton value="agent.mobile">Agent's Mobile</ToggleButton>
                <ToggleButton value="date">Date</ToggleButton>
              </ToggleButtonGroup>
            </div>
            {/* either text or date picker */}
            {filter === "date" ? (
              <DatePicker
                openTo="day"
                views={["year", "month", "day"]}
                onAccept={searchFilter}
                label="Date of Birth"
                value={search}
                onChange={(t: any) => {
                  setSearch(t?.toString()?.substring(0, 15));
                }}
                disableFuture
                renderInput={params => (
                  <TextField
                    {...params}
                    helperText="mm/dd/yyyy"
                    fullWidth
                    onKeyDown={(e: any) => {
                      if (e.key === "Enter" || e.key === "Return") {
                        searchFilter();
                      }
                    }}
                  />
                )}
              />
            ) : (
              <TextField
                label={`Enter ${filter}`}
                variant="outlined"
                fullWidth
                value={search}
                onChange={(t: React.ChangeEvent<HTMLInputElement>) =>
                  setSearch(t.target.value)
                }
                // search on hit enter
                onKeyDown={(e: any) => {
                  if (e.key === "Enter" || e.key === "Return") {
                    if (filter === "id") {
                      searchPayment();
                    } else {
                      searchFilter();
                    }
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={filter === "id" ? searchPayment : searchFilter}
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={
                  <p style={{color: "green"}}>Search is case sensitive</p>
                }
              />
            )}
          </Paper>
        </Grid>
        {/* add */}
        <Grid item xs={12} sm={6}>
          <Paper elevation={elevation} sx={{padding: 2, height: "100%"}}>
            <h2>Create a Payment</h2>
            {/* navigate to add agent page */}
            {user?.userEmail !== "admin@ramnaair.com" && (
              <Button
                endIcon={<AddIcon />}
                variant="outlined"
                onClick={() => navigate(addPaymentLink)}
              >
                Add Payment
              </Button>
            )}
          </Paper>
        </Grid>
        {/* data table */}
        <Grid item xs={12} sx={{paddingBottom: 10}}>
          {loading ? (
            <div className={styles.skeletonContainer}>
              <Skeleton animation="wave" variant="rectangular" height={500} />
            </div>
          ) : (
            <Grid item xs={12}>
              {/* data, and pagelimit use state variables */}
              <PaymentsTable
                data={data}
                setFirst={setFirst}
                setLast={setLast}
                setLoading={setLoading}
                page={page}
                setPage={setPage}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </AdminPage>
  );
};

export default Payments;
