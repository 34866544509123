import {
    query,
    limit,
    orderBy,
    startAfter,
    endBefore,
} from "firebase/firestore";


// determine the type of query, based on search params
export const getPaymentsQuery = (first: string | null, last: string | null, paymentsRef: any, numOfPage: number) => {
    if (first) {
        return query(
            paymentsRef,
            orderBy("date", "asc"),
            endBefore(first),
            limit(numOfPage)
        );
    } else if (last) {
        return query(
            paymentsRef,
            orderBy("date", "asc"),
            startAfter(last),
            limit(numOfPage)
        );
    } else {
        return query(paymentsRef, orderBy("date", "asc"), limit(numOfPage));
    }
};