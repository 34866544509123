import React from "react";
import {Grid, TextField} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {IAgentFormProps} from "./types";

const AddAgentForm: React.FC<IAgentFormProps> = ({
  name,
  setName,
  mobile,
  setMobile,
  company,
  setCompany,
  note,
  setNote,
  uploadData,
  backdrop,
}) => {
  return (
    <Grid container spacing={2} sx={{padding: 4}}>
      {/* name */}
      <Grid item xs={12} md={6}>
        <TextField
          variant="outlined"
          value={name}
          onChange={(t: React.ChangeEvent<HTMLInputElement>) =>
            setName(t.target.value)
          }
          fullWidth
          label="Name"
          required
        />
      </Grid>
      {/* mobile */}
      <Grid item xs={12} md={6}>
        <TextField
          variant="outlined"
          value={mobile}
          onChange={(t: React.ChangeEvent<HTMLInputElement>) =>
            setMobile(t.target.value)
          }
          fullWidth
          label="Mobile"
          type="number"
          required
        />
      </Grid>
      {/* company */}
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          value={company}
          onChange={(t: React.ChangeEvent<HTMLInputElement>) =>
            setCompany(t.target.value)
          }
          fullWidth
          label="Company (Optional)"
        />
      </Grid>
      {/* note */}
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          value={note}
          onChange={(t: React.ChangeEvent<HTMLInputElement>) =>
            setNote(t.target.value)
          }
          fullWidth
          label="Note"
          multiline
          rows={4}
        />
      </Grid>
      <Grid item xs={12}>
        <LoadingButton
          loading={backdrop}
          onClick={uploadData}
          fullWidth
          variant="contained"
        >
          Save Agent
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default AddAgentForm;
