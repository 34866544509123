import React, {forwardRef} from "react";
import Dialog from "@mui/material/Dialog";
import {AppBar} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {TransitionProps} from "@mui/material/transitions";

// component
import AddAgentForm from "src/Components/AddAgentForm";
import {IDialogProps} from "../AgentDetails/types";

// transition effect for dialog
const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UpdateAgent: React.FC<IDialogProps> = ({
  open,
  onClose,
  data,
  name,
  setName,
  mobile,
  setMobile,
  company,
  setCompany,
  note,
  setNote,
  uploadData,
  backdrop,
}) => {
  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{position: "relative"}}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
              Agent {data?.name}
            </Typography>
          </Toolbar>
        </AppBar>
        {/* pass form props */}
        <AddAgentForm
          name={name}
          setName={setName}
          mobile={mobile}
          setMobile={setMobile}
          company={company}
          setCompany={setCompany}
          note={note}
          setNote={setNote}
          uploadData={uploadData}
          backdrop={backdrop}
        />
      </Dialog>
    </div>
  );
};

export default UpdateAgent;
