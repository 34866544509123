import React, {forwardRef} from "react";
import Dialog from "@mui/material/Dialog";
import {AppBar} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {TransitionProps} from "@mui/material/transitions";

// component
import {IDialogProps} from "./types";
import AddOrgForm from "../../Components/AddOrgForm";

// transition effect for dialog
const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UpdateOrg: React.FC<IDialogProps> = ({
  open,
  onClose,
  data,
  name,
  setName,
  email,
  setEmail,
  validateEmail,
  mobile,
  setMobile,
  address,
  setAddress,
  noOfWork,
  setNoOfWork,
  noWorkError,
  note,
  setNote,
  validateNoOfWork,
  uploadData,
  error,
  backdrop,
}) => {
  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{position: "relative"}}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
              Organization: {data?.name}
            </Typography>
          </Toolbar>
        </AppBar>
        {/* pass form props */}
        <AddOrgForm
          name={name}
          setName={setName}
          email={email}
          setEmail={setEmail}
          mobile={mobile}
          setMobile={setMobile}
          address={address}
          setAddress={setAddress}
          noOfWork={noOfWork}
          setNoOfWork={setNoOfWork}
          noWorkError={noWorkError}
          note={note}
          setNote={setNote}
          validateNoWork={validateNoOfWork}
          uploadData={uploadData}
          error={error}
          backdrop={backdrop}
        />
      </Dialog>
    </div>
  );
};

export default UpdateOrg;
