import styled from "@emotion/styled";

export const MapsContainer = styled.div`
  .map {
    height: 450px;
    width: 90%;
  }

  .iframe-container {
    background-color: #faf9f6;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    padding-top: 40px;
    padding-bottom: 40px;
    height: 100%;
  }

  .label {
    font-size: 1rem;
    margin-top: 10px;
    font-weight: bold;
    font-style: italic;
  }

  @media (max-width: 768px) {
    .map {
      height: 300px;
    }
  }
`;
