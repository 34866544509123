import React from "react";
import Lottie from "lottie-react";

// animation
import anim from "../../assets/lottie/404.json";
import Page from "src/Components/Page";

const NotFound: React.FC = () => {
  return (
    <Page>
      <div
        style={{
          display: "grid",
          placeItems: "center",
          marginBottom: 100,
        }}
      >
        <Lottie animationData={anim} />
        <h1>Invalid Route</h1>
      </div>
    </Page>
  );
};

export default NotFound;
