import React from "react";
import styles from "./styles.module.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Grid} from "@mui/material";

// data
import dataArr from "../../data/professions.json";

interface professionType {
  heading: string;
  jobs: string[];
}

const Industries: React.FC = () => {
  const data = dataArr;

  return (
    <div>
      <div className={styles.container}>
        <div style={{padding: 40}}>
          <h1>Across Industries</h1>
          <Grid container spacing={1}>
            {/* professions */}
            <Grid item xs={12}>
              {/* archi */}
              {data?.map((i: professionType) => (
                <Accordion key={i.heading}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="jobs"
                  >
                    <Typography style={{fontWeight: "bold"}}>
                      {i?.heading}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul>
                      {i?.jobs?.map((j: string) => (
                        <li key={j}>{j}</li>
                      ))}
                    </ul>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Industries;
