import React, { useState, useEffect, useContext } from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { WorkerType } from "../../interfaces/Workers";

// firebase
import {
  collection,
  getFirestore,
  getDocs,
  query,
  where,
} from "firebase/firestore";

// mui
import {
  Grid,
  Paper,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Skeleton,
} from "@mui/material";

// icons
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";

// components
import AdminPage from "src/Components/AdminPage";
import { getWorkersQuery } from "../../utils/getWorkersQuery";
import { numOfPage } from "src/constants/numOfPage";
import { elevation } from "src/constants/elevation";
import { addWorkerLink } from "../../constants/urls";
import WorkersTable from "../../Components/WorkersTable";
import { UserContext } from "src/context/UserContext";

const Workers: React.FC = () => {
  // @ts-ignore
  const { user } = useContext(UserContext);
  const [data, setData] = useState<WorkerType[]>([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);

  // for pagination
  const [first, setFirst] = useState<string | null>(null); //grab the first obj for prev pagination
  const [last, setLast] = useState<string | null>(null); //grab the last obj name for next and prev pagination
  const [page, setPage] = useState(1); //check if it is the first page, where go back icon should not be visible on the table
  // search param
  const [filter, setFilter] = useState("name");
  // hooks
  const db = getFirestore();
  const navigate = useNavigate();

  // query obj for firebase
  const workersRef = collection(db, "workers");

  // grab data from firebase
  const grabData = async () => {
    const q = getWorkersQuery(first, last, workersRef, numOfPage);

    try {
      const arr: WorkerType[] = [];
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc: any) => {
        const data = doc.data();
        const appendObj = { ...data, id: doc.id };
        // @ts-ignore
        arr.push(appendObj);
      });

      setData(arr);
      setLoading(false);
    } catch (e) {
      toast.error("Something went wrong. Please reload the window");
      console.log(e);
    }
  };

  // search for worker
  const searchData = async () => {
    setLoading(true);
    try {
      // search using name
      const q = query(collection(db, "workers"), where(filter, "==", search));
      const arr: WorkerType[] = [];

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc: any) => {
        const data = doc.data();
        const appendObj = { ...data, id: doc.id };
        // @ts-ignore
        arr.push(appendObj);
      });
      // set search result to data
      setData(arr);
      setLoading(false);
    } catch (e) {
      toast.error("Something Went Wrong, Please try again!");
      setLoading(false);
    }
  };

  // search for workers
  useEffect(() => {
    // if query is empty, grab default data
    if (search === "") {
      grabData();
    }
  }, [search]);

  // if first and last obj is changed by paginator, grab data
  useEffect(() => {
    grabData();
  }, [first, last]);

  // cleanup
  useEffect(() => {
    return () => {
      setData([]);
      setSearch("");
      setLoading(true);
      setFirst(null);
      setLast(null);
      setPage(1);
      setFilter("name");
    };
  }, []);

  // search filter
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setFilter(newAlignment);
  };

  return (
    <AdminPage heading="Workers">
      <Grid sx={{ padding: 1 }} container spacing={1}>
        {/* search orgs */}
        <Grid item xs={12} sm={6}>
          <Paper elevation={elevation} sx={{ padding: 2 }}>
            <h2>Search for a Worker</h2>
            <div className={styles.filterContainer}>
              {/* filter */}
              <p>Searching using: </p>
              <ToggleButtonGroup
                color="primary"
                value={filter}
                exclusive
                onChange={handleChange}
              >
                <ToggleButton value="name">Name</ToggleButton>
                <ToggleButton value="fathersName">Father's Name</ToggleButton>
                <ToggleButton value="currentPass">Passport Number</ToggleButton>
                <ToggleButton value="mobile">Mobile</ToggleButton>
              </ToggleButtonGroup>
            </div>
            <TextField
              label={`Enter Worker's ${filter}`}
              variant="outlined"
              fullWidth
              value={search}
              onChange={(t: React.ChangeEvent<HTMLInputElement>) =>
                setSearch(t.target.value)
              }
              // search on hit enter
              onKeyDown={(e: any) => {
                if (e.key === "Enter" || e.key === "Return") {
                  searchData();
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={searchData}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText={
                <p style={{ color: "green" }}>
                  *Search is <strong>case sensitive</strong>
                </p>
              }
            />
          </Paper>
        </Grid>
        {/* add */}
        <Grid item xs={12} sm={6}>
          <Paper elevation={elevation} sx={{ padding: 2, height: "100%" }}>
            <h2>Add a Worker</h2>
            {/* navigate to add agent page */}
            {/* block add for manager */}
            {user?.userEmail !== "manager@ramnaair.com" && (
              <Button
                endIcon={<AddIcon />}
                variant="outlined"
                onClick={() => navigate(addWorkerLink)}
              >
                Add Worker
              </Button>
            )}
          </Paper>
        </Grid>
        {/* data table */}
        <Grid item xs={12} sx={{ paddingBottom: 10 }}>
          {loading ? (
            <div className={styles.skeletonContainer}>
              <Skeleton animation="wave" variant="rectangular" height={500} />
            </div>
          ) : (
            <Grid item xs={12}>
              {/* data, and pagelimit use state variables */}
              <WorkersTable
                data={data}
                setFirst={setFirst}
                setLast={setLast}
                setLoading={setLoading}
                page={page}
                setPage={setPage}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </AdminPage>
  );
};

export default Workers;
